import constants from "../constants";
import Cookies from "../coockies/index";

const userState = {
	id: 0,
    sms_code: false,
    redirect: false,
	verify: null,
	sms_count: 0,
    success: null,
    globalSuccess: null,
    message: [],
    globalMessage: {},
    type: 'fiz',
    aditionl_address: 0,
    birthday: '',
    comment: '',
    lang: 'ru',
    fio: '',
    name: '',
    secondname: '',
    phone: '',
    email: '',
    street: '',
    home: '',
    apartament: '',
    region_id: 57,
    second_street: '',
    second_home: '',
    second_apartament: '',
    second_region_id: null,
	delivery_price: 0,
    company_name: '',
    bank_code: '',
    iban: '',
    company_address: '',
    fiscal_code: '',
    nds_code: '',
    company_email: '',
    company_phone: '',
    card: null,
    orders: null,
    goods: null,
    password: '',
    confirm_password: '',
    token: Cookies.get('token')? Cookies.get('token'):false,
};

const UserReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.USER_AUTH: {
            return {
                ...state,
                card: payload.card,
                redirect: true,
                token: payload.token,
                message: []
            };
        }
        case constants.USER_LOGOUT:
            userState['globalSuccess'] = payload.success;
            userState['globalMessage'] = payload.data;
            userState['token'] = false;

            Cookies.remove('token');
            Cookies.remove('discoundCard');
            return {
                ...userState
            };
        case constants.USER_VERIFY:
            return {
                ...state,
				verify: payload
            };
        case constants.USER_MESSAGE:
            return {
                ...state,
                success: payload.success,
                message: payload.data
            };
        case constants.USER_GLOBAL_MESSAGE:
            return {
                ...state,
				token: false,
                globalSuccess: payload.success,
                globalMessage: payload.data
            };
        case constants.USER_ORDERS:
            return {
                ...state,
                orders: payload.orders
            };
        case constants.USER_GOODS:
            return {
                ...state,
                goods: payload.goods
            };
        case constants.USER_DATA:
            return {
                ...state,
				id: payload.user.id,
                redirect: false,
                type: payload.user.type,
                redirectToProfile: false,
                birthday: payload.user.birthday,
                comment: payload.user.comment,
                lang: payload.user.lang,
                fio: payload.user.name + ' ' + payload.user.secondname,
                name: payload.user.name,
                secondname: payload.user.secondname,
                phone: payload.user.phone,
                email: payload.user.email,
                street: action.payload.user.street,
                home: payload.user.home,
                apartament: payload.user.apartament,
                region_id: payload.user.region_id,
                second_street: payload.user.second_street,
                second_home: payload.user.second_home,
                second_apartament: payload.user.second_apartament,
                second_region_id: payload.user.second_region_id,
                company_name: payload.user.company_name,
                bank_code: payload.user.bank_code,
                iban: payload.user.iban,
                company_address: payload.user.company_address,
                fiscal_code: payload.user.fiscal_code,
                nds_code: payload.user.nds_code,
                company_email: payload.user.company_email,
                company_phone: payload.user.company_phone,
                card: payload.user.card,
                token: Cookies.get('token'),
                message: []
            }
        case constants.SMS_FORM:
            return {
                ...state,
                sms_code: payload
            };
        default:
            return {...state};
    }
};

export {
    userState,
    UserReducer
};