import React from "react"
import Logo from '../elements/footer/logo/index'
import Subscribe from '../elements/footer/subscribe/index';
import Who from '../elements/footer/who/index';
import Contacts from '../elements/footer/contacts/index';
import Social from '../elements/footer/social/index';
import Copyright from '../elements/footer/copyright/index';
import styles from './index.module.scss'

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer_row}>
                <Logo />
                <Subscribe />
            </div>
            <div className={styles.footer_row}>
                <Who />
                <Contacts />
                <Social />
            </div>
            <Copyright />
        </footer>
    );
}

export default Footer