import React from 'react'
import styles from './index.module.scss';

const About = (props) => {
    return (
        <div id="fullDescription" className={styles.description}>
            <h4>О товаре</h4>
            <div className={styles.description_content} dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
    );
}

export default About