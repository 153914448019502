import constants from "../constants";
import {setNotFound} from "./BaseAction";
import {messages, toBase64} from './FormAction';
import {encrypt, decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;
const fs = require('fs');

const getPosts = ({dispatch}) => {
    return axios.get('/vacancies').then(resultData => {
        const result = decrypt(resultData.data);
        const payload = result;
        dispatch({payload, type: constants.SET_ERROR});
        dispatch({payload, type: constants.GET_POSTS});
    })
};

const getPost = ({slug, dispatch}) => {
    return axios.get('/vacancies/' + slug).then(resultData => {
        const result = decrypt(resultData.data);
        const payload = result;
        setNotFound(payload.success, dispatch);
        dispatch({
            payload,
            type: constants.GET_POST
        });
    })
};

const sendForm = ({data, dispatch}) => {
    const fileObject = data.get('pdf');

    toBase64(fileObject,function (fileObject,base64) {
        data.delete('pdf');
        data.append('file_name', fileObject.name);
        data.append('file_data', base64);
        const jsonObject = Object.fromEntries(data);

        return axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(() => {
            axios.post(constants.API_URL + '/vacancies/send', {data: encrypt(JSON.stringify(jsonObject))}).then(resultData => {
                const response = decrypt(resultData.data);
                messages(dispatch, 'vacanciViewForm', response.success, response.data, 'left');
            });
        });
    });
}

export {getPost, getPosts, sendForm};
