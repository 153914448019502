import React from 'react';
import {useTranslation} from 'react-i18next';
import Last from "./last/index";
import Header from "./header/index";
import Product from './product/index';
import Pagination from '../../../ui/pagination/index';
import Loader from '../../../ui/loader/index'
import styles from './index.module.scss';

const Grid = (props) => {
	const {t} = useTranslation();
    const {params} = props.match;
    const {gen, cat, subcat} = params;

    return (
        <div id="grid" className={styles.grid}>
            {gen === 'last' ? (<Last />):null}
            <Header {...props} total={props.total} shopSort={props.productSort} getParams={props.getParams}/>
            {props.isLoading? (
                <Loader />
            ):(
                <>
                {typeof props.products !== 'undefined' && props.products.length > 0 ? (
                    <>
                    {Object.entries(props.products).map(([key, val]) => (
                        <React.Fragment key={val.id}>
                            <div className={styles.grid_product}>
                                <Product product={val}/>
                            </div>
                        </React.Fragment>
                    ))}

                    <Pagination {...props} link={'/' + props.lang + '/shop/' + gen + '/' + ((typeof cat !== 'undefined') ? cat + '/' : '') + ((typeof subcat !== 'undefined') ? subcat + '/' : '')} />
          
                    </>
                ) : (
                    <div className={styles.grid_empty}>{t("products_not_found")}</div>
                )}
                </>
            )}
        </div>
    );
};

export default Grid;
