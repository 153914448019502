import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Cookies from "../../../state/coockies/index";
import {useDataState, useDataDispatch} from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import {getUser, applyUserCard, sendSmsCode} from "../../../state/actions/UserAction";
import {showBtnLoader} from "../../../state/actions/FormAction";
import Sidebar from './sidebar/index';
import Input from '../../../ui/form/input/index';
import Button from '../../../ui/form/button/index';
import Loader from '../../../ui/loader/index'
import styles from './index.module.scss';

const DiscoundCard = (props) => {
    const {t} = useTranslation();
    const {user, base} = useDataState();
    const [form, setForm] = useState({});
    const [smsCode, setsmsCode] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDataDispatch();

    const {isLoading} = useAsyncDataFetch({promiseFn: getUser, dispatch}, false, {});
    if (isLoading) return <Loader />;


    if (typeof Cookies.get('token') === 'undefined' || user.token !== Cookies.get('token')) {
        return (
            <Redirect push to={{pathname: "/", state: {ssr: false}}}/>
        )
    }

    const changeHandler = (e) => {
        user.success = null;
        const name = e.target.name;
        const value = e.target.value;

        if(name === 'sms_code') {
            setsmsCode(value);
        }else {
            setForm({...form, [name]: value});
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

		showBtnLoader(dispatch, 'applyUserCardForm', true);
        applyUserCard({dispatch, data});
    }

    const handleSubmitCode = (e) => {
        e.preventDefault();

		showBtnLoader(dispatch, 'applyUserCardSmsForm', true);
        sendSmsCode({dispatch, smsCode});
    }

    return (
        <div className={styles.main}>
            <div className={styles.main_title}>
                <h1>{t('profile')} <span>{user.name} {user.secondname}</span></h1>
            </div>

            <Sidebar Lang={props.Lang} card={user.card} />

            <div className={styles.content}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                    {user.card !== null ? (
              		<>
                      <div className={styles.content_header}>
                        <div className={styles.content_header_item}>
                          <span
                                className={styles.content_header_item_label}><span>{t('number')}</span> {t('bonus_card')}</span>
                          <span className={styles.content_header_item_card}>{user.card.barcode}</span>
                        </div>
                        <div className={styles.content_header_item}>
                          <span className={styles.content_header_item_label}>{t('card_old_discount')}</span>
                          <span className={styles.content_header_item_discound}>{user.card.sale_sale}%</span>
                        </div>
                        <div className={styles.content_header_item}>
                          <span className={styles.content_header_item_label}>{t('card_new_discount')}</span>
                          <span
                                className={styles.content_header_item_discound_old}>{user.card.sale_new}%</span>
                        </div>
                      </div>
                      <div className={styles.card_data}>
                        <div className={styles.card_data_row}>
                          <div className={styles.card_data_row_col}>
                            <label className={styles.card_data_row_col_label}>Держатель карты</label>
                            <span className={styles.card_data_row_col_text}>{user.card.owner}</span>
                          </div>
                          <div className={styles.card_data_row_col}>
                            <label className={styles.card_data_row_col_label}>Тип карты</label>
                            <span className={styles.card_data_row_col_text}>{user.card.name}</span>
                          </div>
                          <div className={styles.card_data_row_col}>
                            <label className={styles.card_data_row_col_label}>Код карты</label>
                            <span className={styles.card_data_row_col_text}>{user.card.code}</span>
                          </div>
                        </div>
                      </div>
          			</>
              		) : user.sms_code ? (
                        <form className={styles.content_form} onSubmit={handleSubmitCode}>
                            <div className={styles.content_form_row}>
                                <div className={styles.content_form_row_col}>
                                    <Input changeVal={changeHandler} type="text" error={formErrors.phone} name="sms_code"
                                           value={smsCode}
                                           label={t("smscode")}/>
                                </div>
                                <div className={styles.content_form_row_colfbtn}>
                                  	<Button formName="applyUserCardSmsForm" type="submit" text={t("send")}/>
                                </div>
                            </div>
                            <div className={styles.content_form_static}>

                            </div>
                        </form>
                    ) : (
                        <form className={styles.content_form} onSubmit={handleSubmit}>
                            <div className={styles.content_form_row}>
                                <div className={styles.content_form_row_col}>
                                    <Input changeVal={changeHandler} type="text" error={formErrors.phone} name="phone"
                                           value={form.phone}
                                           label={t("phone")}/>
                                </div>
                                <div className={styles.content_form_row_col}>
                                    <Input name="barcode" type="text" value={form.barcode}
                                           label={t('card_barcode')} changeVal={changeHandler} required={false}/>
                                </div>
                                <div className={styles.content_form_row_colfbtn}>
                                  	<Button formName="applyUserCardForm" type="submit" text={t("send")}/>
                                </div>
                            </div>
                            <div className={styles.content_form_static}>

                            </div>
                        </form>

                    )}
                    </>
                )}
            </div>
        </div>
    )
}

export default DiscoundCard
