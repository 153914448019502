import React from 'react';
import queryString from 'query-string';
import {useTranslation} from 'react-i18next';
import {useDataState} from "../../../state/context";
import Last from "./last/index";
import Header from "./header/index";
import Product from './product/index';
import Loader from '../../../ui/loader/index';
import MobileMenu from '../sidebar/menu/mobile/index';
import Collections from '../sidebar/collections/index';
import Pagination from '../../../ui/pagination/index';
import styles from './index.module.scss';

const MobileGrid = (props) => {
    const {t} = useTranslation();
    const {shop, base} = useDataState();
    const {params} = props.match;
    const {gen, cat, subcat} = params;
    const getParams = queryString.parse(props.location.search);

    return (
        <div id="grid" className={styles.grid}>
            <div className={styles.grid_menu}>
                {(gen === 'last' || gen === 'all') || typeof getParams.gender === 'undefined' ? (<h3>{t(gen)}</h3>) : (<h3>{t(getParams.gender)}</h3>)}

                {shop.collections.length > 0 ? (
                    <Collections {...props} currentLang={base.currentLang} items={shop.collections} />
                ) : (
                    <MobileMenu shop={shop} headerMenu={base.headerMenu} currentLang={base.currentLang} params={params} getParams={getParams} {...props} />
                )}
            </div>

            {gen === 'last' ? (<Last />):null}
            <Header {...props} total={props.total} shopSort={props.productSort} getParams={props.getParams}/>
            {props.isLoading? (
                <Loader />
            ):(
                <>
                {typeof props.products !== 'undefined' && props.products.length > 0 ? (
                    <>
                    {Object.entries(props.products).map(([key, val]) => (
                        <React.Fragment key={val.id}>
                            <div className={styles.grid_product}>
                                <Product product={val}/>
                            </div>
                        </React.Fragment>
                    ))}

                    <Pagination {...props} link={'/' + props.lang + '/shop/' + gen + '/' + ((typeof cat !== 'undefined') ? cat + '/' : '') + ((typeof subcat !== 'undefined') ? subcat + '/' : '')} />
                    </>
                ) : (
                    <div className={styles.grid_empty}>{t("products_not_found")}</div>
                )}
                </>
            )}
        </div>
    );
};

export default MobileGrid;
