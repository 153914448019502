import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CalcHeight from '../../../../../HOC/calcHeight';
import CloseXIcon from '../../../../../ui/icons/close';
import SizesPage from '../../../../pages/sizesPage';
import styles from './index.module.scss';

const Page = (props) => {
	const {t} = useTranslation();
    const [height, setHeight] = useState({height: '100%'});

    const clickHandler = () => {
        props.closeSize(false)
        document.getElementsByTagName("body")[0].style.overflowY  = "scroll";
    }

    if(props.show) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }

	useEffect(() => {
		setHeight(CalcHeight('header', '100%'));
        window.addEventListener("scroll", setPageHeight);

        return () => {
            window.removeEventListener('scroll', setPageHeight);
        };
    }, []);

	const setPageHeight = (e) => {
        setHeight(CalcHeight('header', '100%'));
    };

    return (
        <>
        <div className={`${styles.page} ${props.show ? styles.page_show : ''}`} style={height} onClick={() => clickHandler()} />
        <div className={`${styles.page_content} ${props.show ? styles.page_content_show : ''}`} style={height}>
            <div className={styles.page_content_header}>
                    <span className={styles.page_content_header_text}>
                        {t('tsizes-title')}
                    </span>
                <div onClick={() => clickHandler()}>
                    <CloseXIcon name="close-x" color="#adadaa" size={15}  />
                </div>
            </div>
            <SizesPage params={props.params} scroll={true} />
        </div>
        </>
    );
}

export default Page