import React from 'react'
import { Link } from 'react-router-dom'
import LogoIcon from '../../../../ui/icons/logo'
import styles from './index.module.scss'

const Logo = () => {
    return (
        <div className={styles.logo}>
            <Link to="/" className={styles.logo_link}>
                <LogoIcon name="logo" color="#fff" size={56} />
            </Link>
        </div>
    );
}

export default Logo