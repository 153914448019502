import React from 'react'
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const PriceSelect = (props) => {
    const {t} = useTranslation();
	const items = (typeof props.items === 'object')? props.items:JSON.parse(props.items);
    let value = props.data.form.select_price;
    const changeHandler = (e) => {
        value = e.target.value
        props.data.setForm({...props.data.form, select_price: value, price: value, custom_price: ""})
    }

    return (
        <div className={styles.wrapper}>
            <label className={styles.wrapper_label}>{t('select')}</label>
            <select value={value} onChange={(e) => changeHandler(e)} className={styles.wrapper_select}>
                <option value="">{t('select')}</option>
                {Object.entries(items).sort().map(([num, item]) => (
                    <option key={num} value={item[0]}>{item[0]}</option>
                ))}
            </select>
        </div>
    );
}

export default PriceSelect