import React, {useRef, useEffect} from 'react';
import {useDataDispatch} from "../../../../state/context";
import {recountCart, hiddeMiniCart, showMiniCart} from "../../../../state/actions/CartAction";
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import Product from '../../../cart/checkout/product/index';
import Certificate from '../../../cart/checkout/certificate/index';
import CartIcon from '../../../../ui/icons/cart';
import CloseXIcon from '../../../../ui/icons/close';
import styles from './index.module.scss';

const MiniCart = (props) => {
    const {t} = useTranslation();
    const cartBlock = useRef(null);
    const cartLink = useRef(null);
    const count = Object.keys(props.products).length + Object.keys(props.certificates).length;
    const dispatch = useDataDispatch();

    const handleToggle = () => {
        if(props.toggle) {
            hiddeMiniCart(dispatch);
        } else {
            showMiniCart(dispatch);
        }
    };

    const handleClose = () => {
        hiddeMiniCart(dispatch);
    };

    const handleClickOutside = (event) => {
        if (cartBlock.current && !cartBlock.current.contains(event.target) && cartLink.current && !cartLink.current.contains(event.target)) {
            hiddeMiniCart(dispatch);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        recountCart(dispatch);
    }, [dispatch]);

    return (
        <>
            <div ref={cartLink} className={styles.cart} onClick={handleToggle}>
                <span className={styles.cart_number}>{count}</span>
                <CartIcon name="cart" color="#fff" size={28} />
            </div>
            <div ref={cartBlock} className={`${styles.cart_slide} ${props.toggle ? styles.cart_slide_open:''}`}>
                <div className={styles.cart_slide__header}>
                    <h4>{t('cart_count')} ({count})</h4>
                    <span onClick={handleClose}>
                        <CloseXIcon name="close-x" color="#000" size={14} />
                    </span>
                </div>
                <div className={styles.cart_slide__body}>
                    {count > 0? (
                        <>
                  		{props.certificates && Object.keys(props.certificates).length > 0 ? (
                            Object.entries(props.certificates).map(([size, item]) => (
                                <>
                                <Certificate key={size} size={size} certificat={item} />
                                </>
                            ))
                        ):null}
                  
                        {props.products && Object.keys(props.products).length > 0 ? (
                            Object.entries(props.products).map(([barcode, item]) => (
                                <Product key={barcode} barcode={barcode} {...item} />
                            ))
                        ):null}
                        </>
                    ):(
                        <p className={styles.cart_slide__body_empty}>{t('empty_cart')}</p>
                    )}
                </div>
                <div className={styles.cart_total}>
                    <span className={styles.cart_total_text}>{t('sub_total')}</span>
                    <span className={styles.cart_total_num}>{props.baseTotal} {t("currency")}</span>
                </div>
                <div className={styles.cart_slide__footer}>
                    <Link onClick={handleClose} className={styles.cart_slide__footer_btn} to={"/" + props.currentLang + "/cart"}>{t('checkout')}</Link>
                    <span onClick={handleClose} className={styles.cart_slide__footer_close}>{t('continue_shoping')}</span>
                </div>
            </div>
        </>
    );
};

export default MiniCart