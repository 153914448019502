import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import TagManager from 'react-gtm-module';
import { useSSR } from "react-i18next";
import App from "./App";
import "./i18n";

const tagManagerArgs = {
    gtmId: 'GTM-P8MWKG9H'
}
TagManager.initialize(tagManagerArgs)

const AppContainer = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);

  return (
      <BrowserRouter>
          <ScrollToTop>
              <App />
          </ScrollToTop>
      </BrowserRouter>
  );
};

// Suspense is not supported in SSR currently, and on server side we will use Static router, so this 2 is used here
ReactDOM.hydrate(<AppContainer />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
