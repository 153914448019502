import React, {createContext, useContext, useReducer} from "react";
import PropTypes from "prop-types";
import combineReducers from 'react-combine-reducers';
import {formState, FormReducer} from "./reducers/FormReducer";
import {userState, UserReducer} from "./reducers/UserReducer";
import {baseState, BaseReducer} from "./reducers/BaseReducer";
import {pageState, PageReducer} from "./reducers/PageReducer";
import {vacancyState, VacancyReducer} from "./reducers/VacancyReducer";
import {shopState, ShopReducer} from "./reducers/ShopReducer";
import {cartState, CartReducer} from "./reducers/CartReducer";
import {commentState, CommentsReducer} from "./reducers/CommentsReducer";
import withSSR from "../HOC/withSSR";

const DataStateContext = createContext();
const DataDispatchContext = createContext();

const useDataState = () => {
    const context = useContext(DataStateContext);
    if (!context) {
        throw Error("useDataState must be used inside DataProvider!");
    }
    return context;
};

const useDataDispatch = () => {
    const context = useContext(DataDispatchContext);
    if (!context) {
        throw Error("useDataDispatch must be used inside DataProvider!");
    }
    return context;
};

const [rootReducer, rootState] = combineReducers({
	formState: [FormReducer, formState],
    user: [UserReducer, userState],
    page: [PageReducer, pageState],
	vacancies: [VacancyReducer, vacancyState],
    base: [BaseReducer, baseState],
    cart: [CartReducer, cartState],
    shop: [ShopReducer, shopState],
    comments: [CommentsReducer, commentState]
});

const DataProvider = ({initState, children}) => {
    const [state, dispatch] = useReducer(rootReducer, initState);

    return (
        <DataStateContext.Provider value={state}>
            <DataDispatchContext.Provider value={dispatch}>
                {children}
            </DataDispatchContext.Provider>
        </DataStateContext.Provider>
    );
};

DataProvider.propTypes = {
    initState: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
};

const DataProviderSSR = withSSR(DataProvider, rootState, "DataProvider");

export {useDataState, useDataDispatch, DataProviderSSR as DataProvider};
