import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import NexArrowIcon from '../../ui/icons/nextArrow';
import PrevArrowIcon from '../../ui/icons/prevArrow';
import styles from './index.module.scss';

const Pagination = (props) => {
    const {page} = props.getParams;
    const [pageNumbers, setPageNumbers] = useState([]);
    const [currPage, setcurrPage] = useState(page);
	const getParams = props.getParams;

    useEffect(()=>{
        setcurrPage((typeof page === 'undefined' || isNaN(page) || page < 1) ? 1:page);
        setPageNumbers(getPageNumbers(parseInt(currPage), parseInt(props.pageCount)));
    }, [props.getParams]);

    return (
        <div className={styles.pagination}>
            {pageNumbers.length > 1 ? (
                <ul className={styles.pagination_paginations}>
                    {getPrevPage(props.link, parseInt(currPage), parseInt(props.pageCount), getParams)}

                    {pageNumbers.map((number) => (
                        <li key={number} className={styles.pagination_paginations_item} >
                            <Link to={"?" + queryString.stringify({...getParams, ...{page: number}})} className={`${styles.pagination_paginations_item_link} ${parseInt(currPage) === parseInt(number) ? styles.active : ''}`}>{number}</Link>
                        </li>
                    ))}

                    {getNextPage(props.link, parseInt(currPage), parseInt(props.pageCount), getParams)}
                </ul>
            ):null}
        </div>
    );
}

function getPrevPage(link, currPage, total, getParams) {
    const page = currPage - 1;

    if(page > 0 && page < total) {
        return (
            <li key="prev_page" className={styles.pagination_paginations_prev}>
                <Link to={"?" + queryString.stringify({...getParams, ...{page: page}})} className={styles.pagination_paginations_prev_link}>
                    <PrevArrowIcon name="chevron-right" color="#fff" size={12}/>
                </Link>
            </li>
        );
    }

    return null;
}

function getNextPage(link, currPage, total, getParams) {
    const page = currPage + 1;

    if(page > 0 && page <= total) {
        return (
            <li key="next_page" className={styles.pagination_paginations_next}>
                <Link to={"?" + queryString.stringify({...getParams, ...{page: page}})} className={styles.pagination_paginations_next_link}>
                    <NexArrowIcon name="chevron-right" color="#fff" size={12}/>
                </Link>
            </li>
        );
    }

    return null;
}

function getPageNumbers(currPage, total) {
    let start = 1;
    let end = total;

    const pageNumbersArr = [];
    const showPageNumbers = 2;

    if((showPageNumbers * 2 + 1) < total) {
        if ((currPage - showPageNumbers) <= 0) {
            start = 1;
            end = showPageNumbers * 2 + 1;
        }

        if ((currPage - showPageNumbers) > 0 && (currPage + showPageNumbers) <= total) {
            start = currPage - showPageNumbers;
            end = currPage + showPageNumbers;
        }

        if ((currPage + showPageNumbers) >= total) {
            start = total - (showPageNumbers * 2 + 1);
        }
    }

    for (let i = start; i <= end; i++) {
        pageNumbersArr.push(i);
    }

    return pageNumbersArr;
}
                                
export default Pagination;
