import React from 'react';
import styles from './index.module.scss';

const Messages = ({status, data, align}) => {
    return (
        <div className={status? styles.success:styles.error} style={{'textAlign': align}}>
            {Object.entries(data).map(([key, val]) => (
                <>
                {typeof val === 'object' ? (
                    <>
                    {Object.entries(val).map(([k, v]) => (
                        <p key={k}>{v}</p>
                    ))}
                    </>
                ):(
                    <p key={key}>{val}</p>
                )}

                </>
            ))}
        </div>
    );
};

export default Messages