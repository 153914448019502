const constants = {
	BASE_URL: "http://pumaarmenia.am",
    API_URL: 'https://api.pumaarmenia.am/api',
	API_BASE_URL: 'https://api.pumaarmenia.am',
    FORM_BTN_LOADER: "FORM_BTN_LOADER",
    FORM_MESSAGE: "FORM_MESSAGE",
    GET_COMMENTS: "GET_COMMENTS",
    ADD_COMMENT: "ADD_COMMENT",
    USER_AUTH: "USER_AUTH",
    USER_DATA: "USER_DATA",
    USER_ORDERS: "USER_ORDERS",
    USER_GOODS: "USER_GOODS",
    USER_MESSAGE: "USER_MESSAGE",
    USER_VERIFY: "USER_VERIFY",
    USER_GLOBAL_MESSAGE: "USER_GLOBAL_MESSAGE",
    USER_LOGOUT: "USER_LOGOUT",
    SET_LANG: "SET_LANG",
    GET_DATA: "GET_DATA",
    GET_PAGE: "GET_PAGE",
    GET_PAGE_ERROR: "GET_PAGE_ERROR",
    GET_POSTS: "GET_POSTS",
    GET_POST: "GET_POST",
    GET_SHOP: "GET_SHOP",
    GET_SHOP_ERROR: "GET_SHOP_ERROR",
    GET_SHOP_MORE: "GET_SHOP_MORE",
    SHOP_SEARCH: "SHOP_SEARCH",
    GET_SHOP_SIDEBAR: "GET_SHOP_SIDEBAR",
    GET_PRODUCT: "GET_PRODUCT",
	GET_AJAX_PRODUCT: "GET_AJAX_PRODUCT",
    ADD_TO_CART_ERROR: "ADD_TO_CART_ERROR",
    CART_DATA: "CART_DATA",
    SHOW_MINI_CART: "SHOW_MINI_CART",
    HIDDE_MINI_CART: "HIDDE_MINI_CART",
    SET_SSR: "SET_SSR",
    SET_ERROR: "SET_ERROR",
    SMS_FORM: "SMS_FORM"
};

export default constants;
