import React, {useRef, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CloseXIcon from '../../../../../ui/icons/close';
import NexArrowIcon from '../../../../../ui/icons/nextArrow';
import BirgerMenu from '../burger/index';
import styles from './index.module.scss';

const Menu = (props) => {
    const {t} = useTranslation();
    const [mobileToggle, setMobileToggle] = useState(false);
    const menuBlock = useRef(null);
    const menuLink = useRef(null);

    const handleClickOutside = (event) => {
        if (menuBlock.current && !menuBlock.current.contains(event.target) && menuLink.current && !menuLink.current.contains(event.target)) {
            setMobileToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <>
        <nav className={styles.nav}>
            <ul className={styles.nav_items}>
                {Object.values(props.menu).map((gender) => (
                    !['all', 'other', 'unisex'].includes(gender.slug) ? (
                        <li key={"header" + gender.slug} className={styles.nav_items_item}>
                            <Link className={styles.nav_items_item_link}
                                  to={typeof gender.link !== 'undefined' ? "/" + props.currentLang + gender.link : "/" + props.currentLang + "/shop/" + gender.slug}
                            >{gender['title_' + props.currentLang]}</Link>
                        </li>
                    ) : null
                ))}
                <li ref={menuLink}>
                    <BirgerMenu mobileToggle={mobileToggle} setMobileToggle={setMobileToggle}/>
                </li>
            </ul>
        </nav>
        <div ref={menuBlock} className={`${styles.nav_slide} ${mobileToggle ? styles.nav_slide_open:''}`}>
            <div className={styles.nav_slide_header}>
                <h4>{t("Menu")}</h4>
                <span onClick={() => setMobileToggle(false)}>
                    <CloseXIcon name="close-x" color="#000" size={14}/>
                </span>
            </div>
            <div className={styles.nav_slide_body}>
                <ul className={styles.nav_slide_body_items}>
                    <li className={styles.nav_slide_body_items_item}>
                        <Link onClick={() => setMobileToggle(false)} className={styles.nav_slide_body_items_item_link}
                              to={"/" + props.currentLang + "/shop/all?attr=promo"}
                        >{t("SALE")} <NexArrowIcon name="chevron-right" color="#ae946d" size={14} /></Link>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Menu