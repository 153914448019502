import React from 'react'
import {useTranslation} from 'react-i18next';
import Checkbox from '../../../../../ui/form/checkbox/index';
import Textarea from '../../../../../ui/form/textarea/index';
import Button from '../../../../../ui/form/button/index';
import styles from './index.module.scss';

const Step = (props) => {
    const {t} = useTranslation();
    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        props.setForm({...props.form, [name]: value});
    }

    return (
        <div className={styles.submit}>
            <div className={styles.submit_comment}>
                <Textarea changeVal={changeHandler} name="comment" label={t("comment")}/>
            </div>
            <div className={styles.submit_btn}>
              	<Button formName="sendOrderForm" type="submit" text={t("checkout")}/>
            </div>
        </div>
    );
};

export default Step