import React, {useEffect} from "react";
import {useTranslation} from 'react-i18next';
import {useDataState} from "../../../state/context";
import StorageService from "../../../state/storage/index";
import Product from './product/index';
import Certificate from './certificate/index';
import styles from './index.module.scss';

const Pending = (props) => {
    const {t} = useTranslation();
    const {params} = props.match;
    const {hash} = params;
    const {cart, base} = useDataState();
    const orderId = cart.order.id;

	useEffect(() => {
		StorageService.remove("eyJpdiI6IkFwbUtBWmpDbm");
		StorageService.remove("eyJpdiI6InlrRnE4anBOQn");
    }, [cart]);


    return (
        <div className={styles.container}>
            <div className={styles.container_header}>
                <h3>Order id: {orderId}</h3>
            </div>
            <div className={styles.container_row}>
                <div className={styles.container_row_left_col}>
                        <h1 className={styles.pending}>Заказ в обработке вам на емейл придет письмо с статусом заказа</h1>
                        <b><span>Внимание! Для получения информации сохраните идентификатор заказа </span>
                            ( {orderId} )</b><br /><br />
                        <p>Спасибо за заказ! Ваш заказ успешно оформлен. Наш менеджер свяжется с Вами в ближайшее время.<br />Если
                            у Вас есть вопросы по Вашему заказу, то Вы можете связаться с нашим консультантом по номеру
                            : +37410 56 73 37.<br /></p>

                    { Object.keys(cart.order.goods).length > 0 ? (
                        <>
                        {Object.entries(cart.order.goods).map(([key, item]) => (
                  			<>
                  				{typeof item.recipient_name !== 'undefined'? (
                 					<Certificate key={key} size={key} certificat={item} />
                                ):(
                                  <Product key={key} product={item} />
                                )}
              				</>
                        ))}
                        </>
                    ) : null}
                </div>
                <div className={styles.container_row_right_col}>
                    <div className={styles.container_row_right_col_label}>
                        {t("fio")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.fio}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("payment_type")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {t('payment_' + cart.order.pay_method)}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("address")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.address}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("phone")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.phone}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("email")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.email}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("comment")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.user.comment}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("delivery")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.delivery_price} {t("currency")}
                    </div>
                    <div className={styles.container_row_right_col_label}>
                        {t("user_order_total")}
                    </div>
                    <div className={styles.container_row_right_col_content}>
                        {cart.order.total} {t("currency")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pending;