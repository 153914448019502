import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string'
import MobileSidebar from '../../sidebar/mobile';
import SortIcon from '../../../../ui/icons/sort';
import FilterIcon from '../../../../ui/icons/filter';
import styles from './index.module.scss';

const Header = (props) => {
    const {t} = useTranslation();
    const [sortToggle, setSortToggle] = useState(false);
    const [sidebarToggle, setSidebarToggle] = useState(false);

    const SortHandler = () => {
        setSortToggle(!sortToggle);
    }

    const SidebarHandler = () => {
        setSidebarToggle(!sidebarToggle);
    }

    return (
        <div className={styles.header}>
            <span className={styles.header_count}>{props.total} {t('poducts_found')}</span>
            <div className={styles.header_sort}>
                <label onClick={SortHandler} className={styles.header_sort_label}>{t('sort_by')} {isMobile || props.mobile ? (<SortIcon name="sort" color="#000" size={20} />):null}</label>
                <ul className={`${styles.header_sort_items} ${sortToggle? styles.sort_items_open:''}`}>
                    {Object.entries(props.shopSort).map(([key, val]) => (
                        <li key={key} className={styles.header_sort_items_item}>
                            <Link onClick={SortHandler}
                                className={`${styles.header_sort_items_item_btn} ${key === props.getParams.sort || (props.getParams.sort === undefined && key === 'price_cheap')? styles.active:null}`}
                                to={"?" + queryString.stringify({...props.getParams, ...{sort:key}})}>
                                {t(val)}
                            </Link>
                        </li>
                    ))}
                </ul>

              	{isMobile || props.mobile ? (
              		<>
              			<label onClick={SidebarHandler} className={styles.header_sort_label}>{t('filter_by')} <FilterIcon name="filter" color="#000" size={20} /></label>
                  		<MobileSidebar {...props} sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          			</>
                ):null}
            </div>
        </div>
    );
}

export default Header