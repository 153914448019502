import constants from "../constants";
import {encrypt, decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

const addLook = ({data, dispatch}) => {
    const formData = new FormData();
    data.files.map(file => {
        formData.append("images[]", file.file);
        if(typeof data.filesData[file.file.name] !== 'undefined') {
            const desc = (typeof data.filesData[file.file.name]['description'] === 'undefined') ? '' : data.filesData[file.file.name]['description'];
            const product = (typeof data.filesData[file.file.name]['name'] === 'undefined') ? '' : data.filesData[file.file.name]['name'];
            formData.append("filesData[" + file.file.name + "][description]", desc);
            formData.append("filesData[" + file.file.name + "][product]", product);
        }

		return formData;
    });

    formData.append("userData[name]", [data.userData.name]);
    formData.append("userData[email]", [data.userData.email]);

	const jsonObject = Object.fromEntries(formData);

    return axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie',{ withCredentials: true }).then(() => {
        axios.post(constants.API_URL + '/looks/add', {data: encrypt(JSON.stringify(jsonObject))}).then(resultData => {
			const response = decrypt(resultData.data);

            dispatch({type: constants.ADD_LOOK, payload: response.data})
        });
    });
}

export {
    addLook
};
