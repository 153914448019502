import React from "react"
import {useTranslation} from 'react-i18next';
import FacebookIcon from '../../../../ui/icons/facebook';
import VkIcon from '../../../../ui/icons/vk';
import InstagramIcon from '../../../../ui/icons/instagram';
import TelegramIcon from '../../../../ui/icons/telegram';
import OkIcon from '../../../../ui/icons/ok';
import TiktokIcon from '../../../../ui/icons/tiktok';
import styles from './index.module.scss';

const Social = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.social}>
            <h3>{t("social")}</h3>
            <nav className={styles.social_nav}>
                <ul className={styles.social_nav_items}>
                    <li className={styles.social_nav_items_item}>
                        <a target='_blank' rel="noopener noreferrer" className={styles.social_nav_items_item_link} href="https://www.facebook.com/profile.php?id=61561930643308&mibextid=LQQJ4d&rdid=yIwlPwHYK2nC9Afy&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FHsPZs31wZ2imnunj%2F%3Fmibextid%3DLQQJ4d">
                            <FacebookIcon name="facebook" color="#fff" size={32} />
                        </a>
                    </li>
                    <li className={styles.social_nav_items_item}>
                        <a target='_blank' rel="noopener noreferrer" className={styles.social_nav_items_item_link} href="https://www.instagram.com/pumayerevan/?igsh=MTZ0MHVxdDM3NnBwdg%3D%3D">
                            <InstagramIcon name="instagram" color="#fff" size={32} />
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Social