import React from "react";
import {Helmet} from "react-helmet";

const Seo = (props) => {

    return (
        <>
        {typeof props.data.seo !== 'undefined' && props.data.seo.title[props.lang] !== null? (
            <Helmet>
                <title>PUMA ARMENIA - Магазин спортивной одежды и обуви</title>
            </Helmet>
        ):(
            <Helmet>
                <title>{props.data['title_' + props.lang]}</title>
                <meta name="description" content={props.data['title_' + props.lang]} />
                <meta name="keywords" content={props.data['title_' + props.lang]} />
            </Helmet>
        )}
        </>
    )
}

export default Seo;