import React, {useState, useEffect} from "react";
import {useDataState} from "../../state/context";
import News from '../elements/header/news/index';
import Logo from '../elements/logo/index';
import Menu from '../elements/header/menu/index';
import Languages from '../elements/header/languages/index';
import Search from '../elements/header/search/index';
import User from '../elements/header/user/index';
import MiniCart from '../elements/header/cart/index';
import MobileMenu from '../elements/header/mobile/menu/index';
import styles from './index.module.scss'

const Header = () => {
    const [scroll, setScroll] = useState(false);
    const {base, shop, user, cart} = useDataState();
    const [mobileToggle, setMobileToggle] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", isSticky);

        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);

    const isSticky = (e) => {
        const {clientHeight} = document.getElementById("news");
        setScroll(window.scrollY > clientHeight);
    };

    return (
        <header id="header" className={`${styles.header} ${scroll ? styles.header_fixed:''}`}>
            <News scroll={scroll} news={base.news} currentLang={base.currentLang} />
            <div className={styles.header_row}>
                <Logo />
                <Menu mobileToggle={mobileToggle} menu={base.headerMenu} pages={base.headerPages} currentLang={base.currentLang} />
                <Search search={shop.search} currentLang={base.currentLang} />
                <Languages langs={base.langs} currentLang={base.currentLang} />
                <User user={user} currentLang={base.currentLang} />
                <MiniCart products={cart.products} certificates={cart.certificates} toggle={cart.toggle} baseTotal={cart.baseTotal} currentLang={base.currentLang} />
                <MobileMenu mobileToggle={mobileToggle} setMobileToggle={setMobileToggle}/>
            </div>
        </header>
    );
}

export default Header