import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDataState, useDataDispatch} from "../../../../../state/context";
import {setDelivery} from "../../../../../state/actions/CartAction";
import Radio from '../../../../../ui/form/radio/index';
import Select from '../../../../../ui/form/select/index'
import Input from '../../../../../ui/form/input/index'
import styles from './index.module.scss';

const Step = (props) => {
    const {t} = useTranslation();
    const {base} = useDataState();
    const dispatch = useDataDispatch();
    const [deliveryType, setDeliveryType] = useState(props.form.delivery);

    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if(name === 'delivery'){
            setDeliveryType(value);
            props.setForm({...props.form, [name]: value, ['region_id']: undefined, ['sector']: undefined, ['store']: undefined, ['regionIdforApi']: false});

            setDelivery(dispatch, value, false, props.form.payment);
        } else if(name === 'region_id' || name === 'sector' || name === 'store') {
            setDelivery(dispatch, props.form.delivery, value, props.form.payment);
            props.setForm({...props.form, [name]: value, ['regionIdforApi']: value});
        }else{
            props.setForm({...props.form, [name]: value});
        }
    }

    const getRegionChilds = (regions, id) => {
        if(typeof id === 'undefined' || id === null) {
            return null;
        }

        const region = getRegionById(regions, id);

        if(region[0].childs.length > 0) {
            return (
                <Select changeVal={changeHandler} name="sector" label="Сектор"
                        data={base.regions[region[0].id]['childs']}
                        active={props.form.sector}/>
            );
        } else if (region[0].parent_id !== 0) {
            return (
                <Select changeVal={changeHandler} name="sector" label="Сектор"
                        data={base.regions[region[0].parent_id]['childs']}
                        active={props.form.region_id}/>
            );
        } else {
            return null;
        }
    }

    return (
        <div className={styles.content}>
            <fieldset>
                <Radio name="delivery" changeVal={changeHandler} value="curier" label={t("delivery_curier")}
                       checked={props.form.delivery}/>
            </fieldset>

            {deliveryType === 'curier' || deliveryType === 'express' ? (
                <>
                <div className={styles.content_items}>
                    <Select changeVal={changeHandler} name="region_id" label={t("region")} defaultOption={t("select_region")}
                            data={base.regions} type={props.form.delivery}
                            active={base.regions[props.form.region_id]}/>

                    {getRegionChilds(base.regions, props.form.region_id)}
                </div>
                <div className={styles.content_items}>
                    <Input name="street" changeVal={changeHandler} value={props.form.street} label={t("street")}/>
                    <Input name="home" changeVal={changeHandler} value={props.form.home} label={t("house")}/>
                    <Input name="apartament" changeVal={changeHandler} value={props.form.apartament}
                           label={t("apartament")}/>
                </div>
                </>
            ) : deliveryType === 'cash' ? (
                <div className={styles.content_items}>
                    <Select changeVal={changeHandler} name="store" label={t("shops")} defaultOption={t("select_shops")}
                            data={base.stores} active={props.form.store} />
                </div>
            ) : null}
        </div>
    );
};

function getRegionById(regions, id) {
    return Object.values(regions).filter(item => parseInt(item.id) === parseInt(id));
}

export default Step