import React, {useState} from 'react'
import queryString from 'query-string'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {useDataDispatch} from "../../../../state/context";
import {search, clearSearch} from "../../../../state/actions/ShopAction";
import Product from '../../../cart/checkout/product/index'
import SearchIcon from '../../../../ui/icons/search';
import NexArrowIcon from '../../../../ui/icons/nextArrow';
import CloseXIcon from '../../../../ui/icons/close';
import styles from './index.module.scss'

const Search = (props) => {
    const dispatch = useDataDispatch();
    const getParams = typeof window !== 'undefined'? queryString.parse(window.location.search):{search: {}};
    const [searchvalue, setSearchvalue] = useState(typeof getParams.search !== "undefined" ? getParams.search:'');
    const [close, setClose] =useState(true);

    const changeHandler = (e) => {
        const value = e.target.value
        setSearchvalue(value);
        setClose(false);

        if(value.length > 2){
            search({value, dispatch});
        }else{
            clearSearch({dispatch});
        }
    };

    const closeHandler = () => {
        setSearchvalue('');
        setClose(true);
    }

    return (
        <div className={styles.search}>
            <form className={styles.search_form} action={"/"+props.currentLang+"/shop/all"} method="get">
                <SearchIcon name="search" color="#fff" size={20} />
                <input name="search" type="search" onChange={(e) => changeHandler(e)} value={searchvalue} className={styles.search_form_input} />
                {searchvalue.length > 0 ? (
                    <span onClick={closeHandler} className={styles.search_form_close}><CloseXIcon name="close-x" color="#adadaa" size={12}  /></span>
                ):null}
            </form>
           {(Object.keys(props.search).length > 0 && !close) ? (
            <div className={styles.search__live}>
                <div className={styles.search__live_half}>
                    {props.search.items.length > 0 ? (
                        <SimpleBar style={{ maxHeight: 350 }}>
                            {props.search.items.map((item) => (
                                <Product key={item.id} size={false} product={item} disableRemove={true} />
                            ))}
                        </SimpleBar>
                    ):(
                        <p>Товары не обнаружены</p>
                    )}
                </div>
                <div className={styles.search__live_half}>
                    <ul className={styles.search__live_half_items}>
                        {props.search.words.map((wordItem) => (
                            <li key={wordItem['title_'+props.currentLang]} className={styles.search__live_half_items_item}>
                                <a className={styles.search__live_half_items_item_link} href="/ru/shop/all?search=521015001230" dangerouslySetInnerHTML={{ __html: wordItem['title_'+props.currentLang] }} />
                            </li>
                        ))}
                    </ul>

                    <ul className={styles.search__live_half_items}>
                        {props.search.links.map((linkItem) => (
                            <li key={linkItem.id} className={styles.search__live_half_items_item}>
                                <a className={styles.search__live_half_items_item_link} href={'/'+props.currentLang+linkItem['title_'+props.currentLang].url}>
                                 {(linkItem.parent)? (
                                        <>
                                        {linkItem.parent['title_'+props.currentLang]['title_'+props.currentLang]}
                                        &nbsp;<NexArrowIcon name="chevron-right" color="#818181" size={10} />&nbsp;
                                        {linkItem['title_'+props.currentLang]['title_'+props.currentLang]}
                                        </>
                                    ):(linkItem['title_'+props.currentLang]['title_'+props.currentLang])}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            ):null}
        </div>
    );
}

export default Search