import constants from "../constants";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

const setLang = ({Lang, dispatch}) => {
    const url = '/' + Lang + window.location.pathname.substring(3) + window.location.search;
    window.history.pushState({}, '', url);
    localStorage.setItem('i18nextLng', Lang);
    const payload = {currentLang: Lang};
    dispatch({
        payload,
        type: constants.SET_LANG
    });
}

const setSSr = ({ssr, dispatch}) => {
    const payload = {ssr: ssr};
    dispatch({
        payload,
        type: constants.SET_SSR
    });
};

const setNotFound = (status, dispatch) => {
    const payload = status;
    dispatch({
        payload,
        type: constants.SET_ERROR
    });
}

export {
    setLang,
    setSSr,
    setNotFound
};
