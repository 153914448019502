import React from 'react';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import StickyBox from "react-sticky-box";
import {Link} from 'react-router-dom';
import {useDataDispatch} from "../../../state/context";
import {useDataState} from "../../../state/context";
import {showBtnLoader} from "../../../state/actions/FormAction";
import {sendCart} from "../../../state/actions/CartAction";
import Product from './product/index';
import Certificate from './certificate/index';
import Steps from './steps/index';
import Total from '../total/index';
import styles from './index.module.scss';

const Checkout = () => {
    const {t} = useTranslation();
    const {cart, base} = useDataState();
	const dispatch = useDataDispatch();

	const sendHandler = (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		data.append('lang', base.currentLang)

		showBtnLoader(dispatch, 'sendOrderForm', true);
        sendCart({data, dispatch});
    };

    return (
        <form onSubmit={sendHandler} className={styles.page}>
            <div className={styles.page_header}>
                <div className={styles.page_header_content}>
                    <h1>{t("checkout_title")}</h1>
                    <Link className={styles.page_header_content_link} to={'/' + base.currentLang + '/cart'}>
                        <span className={styles.page_header_content_link_text}>{t("back_to_cart")}</span>
                    </Link>
                </div>
            </div>
            <div className={styles.page_body}>
                <div className={styles.page_body_left}>
                    <Steps lang={base.currentLang} />
                </div>
                {isMobile || base.mobile ? (
                    <div className={styles.page_body_right}>

                        {Object.entries(cart.certificates).map(([size, item]) => (
                            <Certificate key={size} size={size} certificat={item} disableRemove={true}/>
                        ))}

                        {Object.entries(cart.products).map(([size, item]) => (
                            <Product key={size} size={size} {...item} disableRemove={true}/>
                        ))}
                        <Total title={false}/>
                    </div>
                ):(
                    <StickyBox offsetTop={100} offsetBottom={0}>
                        <div className={styles.page_body_right}>

                            {Object.entries(cart.certificates).map(([size, item]) => (
                                <Certificate key={size} size={size} certificat={item} disableRemove={true}/>
                            ))}

                            {Object.entries(cart.products).map(([size, item]) => (
                                <Product key={size} size={size} {...item} disableRemove={true}/>
                            ))}
                            <Total title={false}/>
                        </div>
                    </StickyBox>
                )}
            </div>
        </form>
    );
};

export default Checkout