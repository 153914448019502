import React from "react";
import StickyBox from "react-sticky-box";
import {isMobile} from 'react-device-detect';
import { useDataState, useDataDispatch } from "../../state/context";
import { getPage } from "../../state/actions/PageAction";
import  useAsyncDataFetch  from "../Hooks/useAsyncDataFetch";
import Seo from "../layout/seo";
import NoFound from './NoFound';
import Loader from '../../ui/loader/index';
import Sidebar from './sidebar/index';
import Banner from './banner/index';
import Looks from '../looks/index';
import SizesPage from './sizesPage';
import styles from './index.module.scss';

const Page = (props) => {
    const { params } = props.match;
    const { slug } = params;
    const {page, base} = useDataState();
    const dispatch = useDataDispatch();
	const ssr = (typeof props.location.state !== 'undefined' && props.location.state.ssr === false)? false : base.ssr;

    const { isLoading } = useAsyncDataFetch({ promiseFn: getPage, dispatch }, ssr, { slug });
    if (isLoading && !page.content.support_top && !page.content.support_bottom) return <Loader />;

    if(!base.success) {
        return <NoFound />;
    }

    return (
        <>
            <Seo data={page.content} lang={base.currentLang} />

            {(page.banners)? Object.entries(page.banners).map(([index, banner]) => (
                <Banner key={index} data={banner} currentLang={base.currentLang} />
            )):null}

            {page.content.support_top || page.content.support_bottom ? (
                <div className={styles.support}>
                    {isMobile || base.mobile ? (
                        <Sidebar Lang={base.currentLang} data={page.menu} slug={slug} />
                    ):(
                        <StickyBox offsetTop={100} offsetBottom={0}>
                            <Sidebar Lang={base.currentLang} data={page.menu} slug={slug} />
                        </StickyBox>
                    )}
                  
                  	{page.content.slug === 'table-sizes' ? (
                  		<div className={styles.support_content}>
                  			<h2>{page.content['title_' + base.currentLang]}</h2>
                  			<SizesPage params={{gen: 'male', cat: 'apparel'}} scroll={false} />
                  		</div>
                  	):(
                  		<div className={styles.support_content}>
                          {!base.success ? (
                              <Loader />
                          ):(
                              <>
                                <h2>{page.content['title_' + base.currentLang]}</h2>

                                <div className={styles.support_content_text}>
                                    <div dangerouslySetInnerHTML={{ __html: page.content['text_' + base.currentLang] }} />
                                </div>
                              </>
                          )}
                      </div>
                  	)}
                </div>
            ):(typeof page.content.notes !== 'undefined' && page.content.notes.length > 0) ? (
				<div className={styles.notes}>
                  {page.content.notes.map((note) => (
                  	<React.Fragment key={note.id}>
                      <div className={styles.notes_title}>
                  		{note['title_' + base.currentLang]}
                      </div>
                      <div id={'note-' + note.id + '-text'} className={styles.notes_text}>
                        <p>{note['text_' + base.currentLang]}</p>
                      </div>
                  	</React.Fragment>
                  ))}
                </div>
			):(
                <div className={styles.page}>
                    <div dangerouslySetInnerHTML={{ __html: page.content['text_' + base.currentLang] }} />
                </div>
            )}
        </>
    );
};

export default Page;
