import React from "react"
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss'

const Contacts = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.contacts}>
            <div className={styles.contacts_row}>
                <div className={styles.contacts_row_col}>
                    <h3>{t("contacts")}</h3>
                    <div className={styles.contacts_row_col_item}>
                        <p><strong>email:</strong> info@fstylegroup.com<br />
                            <strong>gsm:</strong> +374(96) 501 514</p>
                      
                    </div>
                </div>
                <div className={styles.contacts_row_col}>
                    <div className={styles.contacts_row_col_item}>
                        <p><strong>{t("our_stores")}:</strong><br /><br />
                            <strong>{t("our_stores_address")}</strong><br />
                            {t("mon_sat")}  <strong>10:00-20:00</strong><br />
                            {t("phone")}: <strong><a href="tel:+37460700760">+374 (60) 700-760</a></strong></p>
                      <p><br />
                            <strong>{t("our_stores_address_2")}</strong><br />
                            {t("phone")}: <strong><a href="tel:+37460700770">+374 (60) 700-770</a></strong></p>
                    </div>

                </div>
                <div className={styles.contacts_row_col}>
                </div>
            </div>
        </div>
    );
}

export default Contacts