import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDataState } from "../state/context";
import Page from '../view/pages/index';
import NoFound from '../view/pages/NoFound';
import Vacancies from '../view/vacancies/index';
import VacancyDetail from '../view/vacancies/view/index';
import Shop from '../view/shop/index';
import Cart from '../view/cart/index';
import Checkout from '../view/cart/checkout/index';
import Success from '../view/cart/pages/success';
import Pending from '../view/cart/pages/pending';
import Error from '../view/cart/pages/error';
import ProductDetail from '../view/shop/product/index';
import Profile from '../view/user/profile/index';
import DiscoundCard from '../view/user/profile/discoundCard';
import Settings from '../view/user/profile/settings';
import Orders from '../view/user/profile/orders';
import Verify from '../view/user/auth/verify';
import Reset from '../view/user/auth/reset';
import PageSize from '../view/shop/product/sizes/page/index';

const Routes = () => {
    const {user, base} = useDataState();

    return (
        <>
            <Switch>
                <Route path={"/"} exact component={Page} />
                <Route path={"/:lang"} exact component={Page} />
				<Route path={"/:lang/vacancies/:slug"} exact render={(props) => (<VacancyDetail {...props} Lang={base.currentLang} />)} />
                <Route path={"/:lang/vacancies"} exact render={(props) => (<Vacancies Lang={base.currentLang} />)} />
                <Route path={"/:lang/user/settings"} exact render={(props) => (<Settings Lang={base.currentLang} />)} />
                <Route path={"/:lang/user/profile"} exact render={(props) => (<Profile Lang={base.currentLang} />)} />
                <Route path={"/:lang/user/orders"} exact render={(props) => (<Orders Lang={base.currentLang} />)} />
                <Route path={"/:lang/user/card"} exact render={(props) => (<DiscoundCard Lang={base.currentLang} />)} />
                <Route path={"/:lang/page/:slug"} exact component={Page} />
                <Route path={"/:lang/cart"} exact component={Cart} />
                <Route path={"/:lang/checkout"} exact component={Checkout} />
                <Route path={"/:lang/order/success/:hash"} exact component={Success} />
                <Route path={"/:lang/order/pending/:hash"} exact component={Pending} />
                <Route path={"/:lang/order/error"} exact component={Error} />
                <Route path={"/:lang/shop/:gen/:cat/:subcat/:slug"} exact component={ProductDetail} />
                <Route path={"/:lang/shop/:gen/:cat/:subcat"} exact component={Shop} />
                <Route path={"/:lang/shop/:gen/:cat/"} exact component={Shop} />
                <Route path={"/:lang/shop/:gen"} exact component={Shop} />
                <Route path={"/:lang/verify-email/:id/:hash"} exact component={Verify} />
                <Route path={"/:lang/reset-password"} exact component={Reset} />
                <Route path="*" exact component={NoFound} status={404} />
            </Switch>
            { user.redirect ? (<Redirect push to={{pathname: "/" + base.currentLang + "/user/profile/", state: { visible: false }}} />) : null}
        </>
    );
};

export default Routes;