import constants from "../constants";

const commentState = {
    comments: {},
    commentAdd: {}
};

const CommentsReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.GET_COMMENTS: {
            return {
                ...state,
                comments: payload.data.comments
            };
        }
        case constants.ADD_COMMENT: {
            return {
                ...state,
                commentAdd: payload
            }
        }
        default:
            return {...state};
    }
};

export {
    commentState,
    CommentsReducer
};