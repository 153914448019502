import CryptoJS from 'crypto-js';
const API_KEY = '95r6TdoC6DUwmrBjARjkgHf1VWxo4kw0m8yA2y2zCbE=';

function decrypt(data) {
    let encrypted = data;
    let key = API_KEY;

    encrypted = atob(encrypted);
    encrypted = JSON.parse(encrypted);

    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    const value = encrypted.value;
    key = CryptoJS.enc.Base64.parse(key);
    let decrypted = CryptoJS.AES.decrypt(value, key, {
        iv: iv
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

function encrypt(data) {
    let iv = CryptoJS.lib.WordArray.random(16),
        key = CryptoJS.enc.Base64.parse(API_KEY);
    let options = {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, options);
    encrypted = encrypted.toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result = {
        iv: iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
    }
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);
    return CryptoJS.enc.Base64.stringify(result);
}

export {decrypt, encrypt};