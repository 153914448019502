import React from "react"
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';
import constants from "../../../../state/constants";

const Copyright = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.copyright}>
            <div className={styles.copyright_right}>
                <span>{t('copyright_accept')}</span>
                <span className={styles.copyright_right_img}>
                    <img className="lazyload" src={constants.API_BASE_URL + '/images/social_mastercard-64.png'}  alt="Puma Armenia мы принимаем Visa" />
                </span>
                <span className={styles.copyright_right_img}>
                    <img className="lazyload" src={constants.API_BASE_URL + '/images/social_visa-64.png'} alt="Puma Armenia мы принимаем MasterCard" />
                </span>
              	<span className={styles.copyright_right_img}>
                    <img className="lazyload" src={constants.API_BASE_URL + '/images/fastshift.jpg'} alt="Puma Armenia мы принимаем MasterCard" />
                </span>
            </div>
        </div>
    );
}

export default Copyright