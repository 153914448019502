import React from 'react';
import {useDataState} from "../../../state/context";
import InputMask from 'react-input-mask';
import styles from './index.module.scss';

const Input = ({width, type, changeVal, name, value, label, error, required = true}) => {
 	const {base} = useDataState();

    const changeHandler = (e) => {
        changeVal(e)
    }

    return (
        <div className={`${styles.item} ${width === 'half' ? styles.half:width === 'full' ? styles.full:''}`}>
            <label className={styles.item_label}>{label}</label>
            {name === 'phone' || name === 'company_phone'? (
                <InputMask onChange={(e) => changeHandler(e)} mask="+374 99-99-99-99" maskChar=" " value={value} name={name} type={type} className={styles.item_val} required={required} />
            ):(
                <input type={type} onChange={(e) => changeHandler(e)} onBlur={(e) => changeHandler(e)} className={styles.item_val} lang={base.currentLang} name={name} value={value} required={required}  />
            )}

            {typeof error !== 'undefined' ? (
                <span className={styles.item_error}>{error}</span>
            ):null}
        </div>
    );
};

export default Input