import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import { useDataState } from "../../../../state/context";
import {Redirect} from 'react-router-dom'
import Cookies from "../../../../state/coockies/index";
import Icon from '../../../../ui/icons/icons';
import constants from "../../../../state/constants";
import styles from './index.module.scss';

const Order = (props) => {
    const {t} = useTranslation();
    const [visibility, setVisibility] = useState(false)
    const {user, base} = useDataState();

    const closeHandler = (e) => {
        setVisibility(false)
    }

    useEffect(() => {
        setVisibility(props.visibility);
    }, [props]);

    if (typeof Cookies.get('token') === 'undefined' && user.token !== Cookies.get('token')) {
        return (
            <Redirect push to={"/"}/>
        )
    }

    return (
        <>
        {visibility ? (
            <div className={styles.model}>
                <div className={styles.model_content}>
                    <div className={styles.model_content_header}>
                        <h3>{props.title}</h3>
                        <span onClick={closeHandler}>
                                <Icon name="close-x" color="#bbb" size={22}></Icon>
                            </span>
                    </div>
                    <div className={styles.model_content_body}>
                        {props.data !== null && props.data.length > 1 ? (
                            <>
                            {Object.entries(props.data).map(([key, item]) => (
                                <div key={key} className={styles.product}>
                                    <div className={styles.product_thumbnail}>
                                        <img src={constants.API_BASE_URL + '/images/products/jpg/' + item.product.article + item.product.color_num + '185x185.jpg?v6.33'} alt="Puma Black" />
                                    </div>
                                    <div className={styles.product_content}>
                                        <h3>{item.product['title_'+base.currentLang]}</h3>
                                        <ul>
                                            <li>{t("color")}: {item.product.color_name}</li>
                                            <li>{t("size")}: {item.options.split(".")[1]}</li>
                                            <li>{t("article")}: {item.product.article+item.product.color_num}</li>
                                            <li>{t("quantity")}: {item.count}</li>
                                        </ul>
                                    </div>
                                    <div className={styles.product_prices}>
                                            <span className={styles.product_prices_base}>
                                                {item.price}
                                                <span className={styles.product_prices_base_currency}>{t("currency")}</span>
                                            </span>
                                        {item.old_price > 0 ? (
                                            <span className={styles.product_prices_old}>
                                                    {item.old_price}
                                                <span className={styles.product_prices_old_currency}>{t("currency")}</span>
                                                </span>
                                        ):null}
                                    </div>
                                </div>
                            ))}
                            <div className={styles.delivery}>
                                <span className={styles.delivery_label}>{t("delivery")}:</span>
                                <span className={styles.delivery_value}>{props.order.delivery_price} {t("currency")}</span>
                            </div>
                            <div className={styles.total}>
                                <span className={styles.total_label}>{t("user_order_total")}:</span>
                                <span className={styles.total_value}>{props.order.total} {t("currency")}</span>
                            </div>
                            <div className={styles.data_row}>
                                <div className={styles.data_row_item}>
                                    <h4>{t("order_address")}</h4>
                                    <span>{props.order.user.address}</span>
                                    <span>{props.order.user.phone}</span>
                                </div>
                                <div className={styles.data_row_item}>
                                    <h4>{t("delivery_type")}</h4>
                                    <span>{t(props.order.pay_method)}</span>
                                    <span>{t(props.order.delivery_method)}</span>
                                </div>
                            </div>
                            </>
                        ):(
                            <div>Нет данных по заказу</div>
                        )}
                    </div>
                </div>
            </div>
        ):null}
        </>
    )
}

export default Order