import React, {useRef, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CloseXIcon from '../../../../../ui/icons/close';
import SubscribeIcon from '../../../../../ui/icons/subscribe';
import NexArrowIcon from '../../../../../ui/icons/nextArrow';
import styles from './index.module.scss';
import './index.css';

const MobileSubscribe = (props) => {
    const {t} = useTranslation();
    const [value, setValue] = useState('')
    const [subscribeToggle, setSubscribeToggle] = useState(false);
    const subscribeBlock = useRef(null);
    const subscribeLink = useRef(null);

    const handleClickOutside = (event) => {
        if (subscribeBlock.current && !subscribeBlock.current.contains(event.target) && subscribeLink.current && !subscribeLink.current.contains(event.target)) {
            setSubscribeToggle(false);
        }
    };

    const changeHandler = (e) => {
        setValue(e.target.value)
    }

    const toggleSubscribe = () => {
        if (subscribeToggle) setSubscribeToggle(false);
        else setSubscribeToggle(true);
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    }, []);

    return (
        <div>
            <span ref={subscribeLink} onClick={toggleSubscribe}>
                <SubscribeIcon name="subscribe" color="#fff" size={22}/>
            </span>

            <div ref={subscribeBlock} className={`${styles.subscribe_slide} ${(subscribeToggle) ? styles.subscribe_slide_open : ''}`}>
                <div className={styles.subscribe_slide_header}>
                    <h4>{t("subscribe_title")}</h4>
                    <span onClick={() => setSubscribeToggle(false)}>
                    <CloseXIcon name="close-x" color="#000" size={14}/>
                </span>
                </div>
                <div className={styles.subscribe_slide_body}>
                    <div id="mlb2-6779866" className="newsletter ml-subscribe-form mobile">
                        <div id="ml-block-success" className="subscribe-form ml-block-success">
                            <div className="form-section">
                                <p>{t("thk_for_subscription")}</p>
                            </div>
                        </div>
                        <form id="newsletter__form" className="newsletter__form ml-block-form"
                              action="https://app.mailerlite.com/webforms/submit/w2p0p1" data-id="641292"
                              data-code="w2p0p1" method="POST" target="_blank">
                            <input type="hidden" name="ml-submit" value="1"/>
                            <input type="email" name="fields[email]" className="newsletter__form-input"
                                   placeholder="Email*"
                                   value={value} onChange={(e) => changeHandler(e)} autoComplete="email"
                                   spellCheck="false"
                                   autoCapitalize="off"/>

                            <button type="submit" className="newsletter__form-btn">
                                <NexArrowIcon name="chevron-right" color="#fff" size={14}/>
                            </button>
                            <button disabled="disabled" type="button" className="loading">
                                <img loading="lazy" src="https://static.mailerlite.com/images/rolling@2x.gif" width="20" height="20"
                                     alt=""/>
                            </button>
                        </form>
                        <script async defer
                                src="https://static.mailerlite.com/js/w/webforms.min.js?v3772b61f1ec61c541c401d4eadfdd02f"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ml_webform_success_6779866() {
    document.getElementById('ml-block-success').style.display = "block";
    document.getElementById('newsletter__form').style.display = "none";
}

export default MobileSubscribe