import Cookies from "../coockies/index";
import constants from "../constants";

const cartState = {
    order: {},
    toggle: false,
    products: {},
    certificates: {},
    baseTotal: 0,
    sales: {},
    delivery: 0,
    ssr: true
};

const CartReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.SHOW_MINI_CART:
            return {
                ...state,
                toggle: true
            }
        case constants.HIDDE_MINI_CART:
            return {
                ...state,
                toggle: false
            }
        case constants.CART_DATA: {
			const certificates = Cookies.get('certificate');

            return {
                ...state,
                products: payload.products,
                certificates: payload.certificates,
                baseTotal: payload.baseTotal,
                total: payload.total,
                sales: payload.sales,
                delivery: payload.delivery
            };
        }
        default:
            return {...state};
    }
};

export {
    cartState,
    CartReducer
};