import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Product from '../../../../cart/checkout/product/index';
import Certificate from '../../../../cart/checkout/certificate/index';
import CloseXIcon from '../../../../../ui/icons/close';
import styles from './index.module.scss';

const Cart = (props) => {
    const {t} = useTranslation();

    return (
        <div className={`${styles.cart_slide} ${(props.cartToggle)? styles.cart_slide_open:''}`}>
            <div className={styles.cart_slide_header}>
                <h4>{t('cart_count')} ({props.count})</h4>
                <span onClick={() => props.setCartToggle(false)}>
                    <CloseXIcon name="close-x" color="#000" size={14}/>
                </span>
            </div>
            <div className={styles.cart_slide_body}>
                {props.count > 0? (
                    <>
                    {props.certificates && Object.keys(props.certificates).length > 0 ? (
                        Object.entries(props.certificates).map(([size, item]) => (
                            <>
                            <Certificate key={size} size={size} certificat={item} />
                            </>
                        ))
                    ):null}

                    {props.products && Object.keys(props.products).length > 0 ? (
                        Object.entries(props.products).map(([barcode, item]) => (
                            <Product key={barcode} {...item} />
                        ))
                    ):null}
                    </>
                ):(
                    <p className={styles.cart_slide__body_empty}>{t('empty_cart')}</p>
                )}
            </div>
            <div className={styles.cart_total}>
                <span className={styles.cart_total_text}>{t('sub_total')}</span>
                <span className={styles.cart_total_num}>{props.baseTotal} {t("currency")}</span>
            </div>
            <div className={styles.cart_slide_footer}>
                <Link onClick={() => props.setCartToggle(false)} className={styles.cart_slide_footer_btn} to={"/" + props.currentLang + "/cart"}>{t('checkout')}</Link>
                <span onClick={() => props.setCartToggle(false)} className={styles.cart_slide_footer_close}>{t('continue_shoping')}</span>
            </div>
        </div>
    )
}

export default Cart