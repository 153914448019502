import React from 'react';
import {useDataState} from "../../../state/context";
import Logo from '../../elements/mobile/header/logo/index';
import User from '../../elements/mobile/header/user/index';
import MobileSubscribe from '../../elements/mobile/header/subscribe/index';
import Menu from '../../elements/mobile/header/menu/index';
import DiscountIcon from '../../../ui/icons/discount';
import FreeDeliveryIcon from '../../../ui/icons/freeDelivery';
import CardIcon from '../../../ui/icons/card';
import styles from './index.module.scss'

const MobileHeader = () => {
    const {base, user} = useDataState();

    return (
        <>
            <div id="header" className={styles.mobile_header}>
                <div className={styles.mobile_header_top}>
                    <MobileSubscribe />
                    <Logo />
                    <User user={user} currentLang={base.currentLang}/>
                </div>
                <Menu menu={base.headerMenu} currentLang={base.currentLang}/>
            </div>
        </>
    );
}

export default MobileHeader