import React from 'react';
import styles from './index.module.scss';

const Radio = ({changeVal, name, value, label, checked}) => {
    const changeHandler = (e) => {
        changeVal(e)
    }

    return (
        <div className={styles.item}>
            <input onClick={(e) => changeHandler(e)} type="radio" id={`${name}_${value}`} name={name} value={value} defaultChecked={checked == value ? checked:false} autoComplete="off" />
            <label htmlFor={`${name}_${value}`}>{label}</label>
        </div>
    );
};

export default Radio