import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import { addLook } from "../../../../state/actions/LookAction";
import { useDataDispatch } from "../../../../state/context"
import Input from '../../../../ui/form/input/index';
import styles from './index.module.scss';

const Step3 = (props) => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();
    const [data, setData] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();

        addLook({data: {...props.form, ['userData']: data}, dispatch});
        props.setSteps(4);
    }

    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setData({...data, [name] : value});
    }

    return (
        <form className={styles.user} onSubmit={handleSubmit}>
            <h4 className={styles.user_title}>Заполните поля формы</h4>
            <div>
                <Input type="text" name="name" label={t("name")} changeVal={changeHandler} />
                <Input required={false} type="email" name="email" label={t("email")} changeVal={changeHandler} />
            </div>
            <div className={styles.next}>
                <button className={styles.next_btn} type="submit">отправить</button>
            </div>
        </form>
    );
}

export default Step3;