import React from 'react';
import { Link } from 'react-router-dom';
import constants from "../../../../../state/constants";
import styles from './index.module.scss';

const Logo = () => {
    return (
        <Link to="/" className={styles.logo}>
            <img className={styles.logo_img} src={constants.API_BASE_URL + '/images/white-app-armenia.png'} alt="" width="72" height="58" />
        </Link>
    );
}

export default Logo