import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import Cookies from "../../../state/coockies/index";
import { useDataState, useDataDispatch } from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import { getOrders, getProducts } from "../../../state/actions/UserAction";
import Order from "./order/index";
import Sidebar from './sidebar/index';
import Loader from '../../../ui/loader/index'
import styles from './index.module.scss';

const Orders = (props) => {
    const {t} = useTranslation();
    const [modelTitle, setModelTitle] = useState('')
    const [order, setOrder] = useState({})
    const [model, setModel] = useState(false)
    const {user, base} = useDataState();
    const dispatch = useDataDispatch();

    const clickHandler = (key) => {
        setModelTitle(t('order_nr')+ ' ' +user.orders[key].id);
        setOrder(user.orders[key]);

        getProducts(dispatch, user.orders[key].id);
        setModel(true)
    }

    const { isLoading } = useAsyncDataFetch({ promiseFn: getOrders, dispatch }, false, {});
    if (isLoading) return <Loader />;


	if (typeof Cookies.get('token') === 'undefined' || user.token !== Cookies.get('token')) {
        return (
            <Redirect push to={{pathname: "/", state: { ssr: false }}} />
        )
    }

    return (
        <div className={styles.main}>
            <div className={styles.main_title}>
                <h1>{t('profile')} <span>{user.name} {user.secondname}</span></h1>
            </div>

            <Sidebar Lang={props.Lang} card={user.card} />

            <div className={styles.content}>
                <div className={styles.content_order}>
                    {isMobile || base.mobile ? null : (
                    <div className={styles.content_order_row}>
                        <div className={styles.content_order_row_col}>
                            <span className={styles.content_order_header}>{t('order_nr')}</span>
                        </div>
                        <div className={styles.content_order_row_col}>
                            <span className={styles.content_order_header}>{t('status')}</span>
                        </div>
                        <div className={styles.content_order_row_col}>
                            <span className={styles.content_order_header}>{t('order_date')}</span>
                        </div>
                        <div className={styles.content_order_row_col}>
                            <span className={styles.content_order_header}>{t('price')}</span>
                        </div>
                    </div>
                    )}

                    {user.orders ? (
                        <>
                        {Object.entries(user.orders).map(([key, val]) => (
                            <div key={key} onClick={() => clickHandler(key)} className={`${styles.content_order_row} ${styles.content_order_row_hower}`}>
                                <div className={styles.content_order_row_col}>
                                    <span className={styles.content_order_body}>{isMobile || base.mobile ? t('order_nr') + ': ':null}{val.id}</span>
                                </div>
                                <div className={styles.content_order_row_col}>
                                    <span className={styles.content_order_body}>{isMobile || base.mobile ? t('status') + ': ':null}{val.status? (<span className={styles.success}>SUCCESS</span>):(<span className={styles.error}>ERROR</span>)}</span>
                                </div>
                                <div className={styles.content_order_row_col}>
                                    <span className={styles.content_order_body}>{isMobile || base.mobile ? t('order_date') + ': ':null}{val.created_at}</span>
                                </div>
                                <div className={styles.content_order_row_col}>
                                    <span className={styles.content_order_body}>{isMobile || base.mobile ? t('price') + ': ':null}{val.total} {t("currency")}</span>
                                </div>
                            </div>
                        ))}
                        </>
                    ):null}
                </div>
            </div>
            <Order visibility={model} title={modelTitle} data={user.goods} order={order} />
        </div>
    )
}

export default Orders
