import React, {useState} from "react";
import constants from "../../state/constants";
import {useTranslation} from 'react-i18next';
import {useDataState, useDataDispatch} from "../../state/context";
import {getPosts, sendForm} from "../../state/actions/VacancyAction";
import {showBtnLoader} from "../../state/actions/FormAction";
import  useAsyncDataFetch  from "../Hooks/useAsyncDataFetch";
import {validation} from "../../ui/form/validaion/index";
import {Link} from 'react-router-dom';
import NoFound from '../pages/NoFound';
import Input from '../../ui/form/input/index';
import Textarea from '../../ui/form/textarea/index';
import Select from '../../ui/form/select/index';
import File from '../../ui/form/file/index';
import Button from '../../ui/form/button/index';
import Loader from '../../ui/loader/index';
import Seo from "../layout/seo";
import styles from './index.module.scss';

const Vacancies = (props) => {
    const {t} = useTranslation();
	const [formErrors, setFormErrors] = useState({});
    const [form, setForm] = useState({
        fio: '',
        phone: '',
        email: '',
        comment: '',
		birthday: '',
		pdf: false
    });
    const {vacancies, base} = useDataState();
    const dispatch = useDataDispatch();

    const changeHandler = (e) => {
        const name = e.target.name;

		if(name === 'pdf') {
			let fileInput = e.target;
			let filePath = fileInput.value;

			const allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;
             
            if (!allowedExtensions.exec(filePath)) {
                alert(t('Invalid file type'));
            }else{
				const file = fileInput.files[0];
                setForm({...form, ['pdf']: file.name});
				
			}
		}else{
          const value = e.target.value;

          setFormErrors({...formErrors, [name]: validation(name, value)});
          setForm({...form, [name]: value});
		}
    };

	const sendHandler = (e) => {
		e.preventDefault();
		const data = new FormData(e.target);

		showBtnLoader(dispatch, 'vacanciViewForm', true);
        sendForm({data, dispatch});
    };

    const {isLoading} = useAsyncDataFetch({promiseFn: getPosts, dispatch}, base.ssr, {});
    if (isLoading) return <Loader />;

    if (!base.success) {
        return <NoFound />;
    }

    return (
        <>
        <Seo data={vacancies.seo} lang={base.currentLang}/>

        <div className={styles.vacancy}>
            <div className={styles.vacancy__header}>
                <h1><strong>{t('vacancy_title')}</strong></h1>
            </div>
            <div className={styles.vacancy__content}>
                <div className={styles.vacancy__content_banner}>
                    <video className={styles.vacancy__content_banner_video} autoplay="autoplay" loop="loop" muted="muted" playsinline="" width="300" height="150">
						  <source src={constants.API_BASE_URL + '/video/vacancy_video.mp4'} type="video/mp4" />
					</video>
                </div>
                <div className={styles.vacancy__content_row}>
                    { vacancies.posts.length > 0 ? vacancies.posts.map((post, key) => (
                        <div key={key} className={styles.vacancy__content_row_col}>
                            <Link to={'/' + props.Lang + '/vacancies/' + post.slug}
                                  className={styles.vacancy__content_row_col_item}>
                                <div className={styles.vacancy__content_row_col_item_thumbnails}>
                                    <picture>
                                        <source
                                            srcSet={constants.API_BASE_URL + '/uploads/posts/webp/450-450' + post.image + '.webp'}
                                            type="image/webp"/>
                                        <source
                                            srcSet={constants.API_BASE_URL + '/uploads/posts/web/450-450' + post.image + '.jpg'}
                                            type="image/jpeg"/>
                                        <img className={styles.vacancy__content_row_col_item_thumbnails_img}
                                             loading="lazy" alt={post['title_' + props.Lang]}
                                             src={constants.API_BASE_URL + '/uploads/posts/web/450-450' + post.image + '.jpg'}/>
                                    </picture>
                                </div>
                                <div className={styles.vacancy__content_row_col_item_title}>
                                    <h2>{post['title_' + props.Lang]}</h2>
                                </div>
                            </Link>
                        </div>
                    )) : null}
                </div>
            </div>
            <div className={styles.vacancy__aside}>
                <p className={styles.vacancy__aside_text}>
                  	На протяжении 70 лет PUMA производит товары для самых быстрых спортсменов планеты. Каждый день наши 13 000 сотрудников в более чем 120 странах создают и продвигают культовую обувь, одежду и аксессуары, делая свой вклад в развитие спорта по всему миру. Продукция PUMA представлена в таких видах спорта, как футбол, бег, фитнес и мотоспорт.<br /><br />

PUMA - это то, что вы искали. Подайте заявку на одну из множества доступных вакансий и присоединяйтесь в крутой спортивный магазин PUMA Kyrgyzstan.</p>

                <form onSubmit={sendHandler} className={styles.vacancy__aside_form}>
                    <h3>{t('vacancy_form_title')}</h3>
                    <div className={styles.vacancy__aside_form_group}>
                        <h4>{t('vacancy_form_sub_title')}</h4>
                      	<div className={styles.vacancy__aside_form_group_row}>
                          <Input width="half" name="fio" changeVal={changeHandler} error={formErrors.fio} value={form.fio}
                                 label={t('vacancy_form_fio')}/>
                          <Input width="half" name="phone" changeVal={changeHandler} error={formErrors.phone} value={form.phone}
                                 label={t('vacancy_form_phone')}/>
                        </div>
                      	<div className={styles.vacancy__aside_form_group_row}>
                          <Input width="half" name="email" changeVal={changeHandler} error={formErrors.email} value={form.email}
                                 label={t('vacancy_form_email')}/>
                          <Input width="half" name="birthday" type="date" changeVal={changeHandler} error={formErrors.birthday} value={form.birthday}
                                 label={t('vacancy_form_birthday')}/>
                        </div>
                      	 <Select changeVal={changeHandler} name="vacancy" label={t("select_vacancy")}
                                data={vacancies.posts} type="vacancy" />
                        <Textarea changeVal={changeHandler} name="comment" label={t('vacancy_form_about')}/>
                      	<File label={form.pdf ? form.pdf:t('vacancy_form_pdf')} name="pdf" changeVal={changeHandler} />
                        <div className={styles.vacancy__aside_form_group_btn}>
                            <Button formName="vacanciViewForm" type="submit" text={t("vacancy_form_send")}/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
};

export default Vacancies;
