import React from "react";
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import constants from "../../../../state/constants";
import {useDataDispatch} from "../../../../state/context";
import {productRemove} from "../../../../state/actions/CartAction";
import styles from './index.module.scss';

const Product = (props) => {
    const {t} = useTranslation();
    const Lang = 'ru';
    const dispatch = useDataDispatch();

    const handleClick = () => {
        if(props.barcode) {
            const sizes = JSON.parse(props.product.data);
            const filtered = sizes.size.filter(item => String(item.barcode) === props.barcode);
            const removeBarcode = filtered[0].barcode;

            productRemove({removeBarcode, dispatch});
        }
    }

    return (
        <div className={styles.items}>
            <div className={styles.items_thumbnail}>
                <picture>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/webp/200-200' + props.product.image + '.webp'}
                        type="image/webp"/>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}
                        type="image/jpeg"/>
                    <img className={styles.item_thumbnail_img} loading="lazy" alt={props.product['title_'+Lang]}
                         src={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}/>
                </picture>
                {props.disableRemove ? null:(
                    <span onClick={handleClick} className={styles.items_remove}>{t("remove")}</span>
                )}
            </div>
            <div className={styles.items_content}>
                <Link className={styles.items_content_title} to={"/"+Lang+props.product.link}>{props.product['title_'+Lang]}</Link>
                <ol className={styles.items_content_info}>
                    <li className={styles.items_content_info_item}>{t("color")} : {props.product.color_name}</li>
                    <li className={styles.items_content_info_item}>{t("size")} : {props.size}</li>
                    <li className={styles.items_content_info_item}>{t("article")} : {props.product.article+props.product.color_num}</li>
                </ol>

                <div className={styles.items_footer}>
                    {props.count ? (
                        <span className={styles.items_quantity}>{props.count} {t("quantity")}</span>
                    ):null}
                    <div className={styles.items_prices}>
                        <span className={styles.items_prices_base}>
                            {props.product.price}
                            <span className={styles.items_prices_base_currency}>{t("currency")}</span>
                        </span>
                        {props.product.old_price > props.product.price ? (
                            <span className={styles.items_prices_old}>
                            {props.product.old_price}
                                <span className={styles.items_prices_old_currency}>{t("currency")}</span>
                        </span>
                        ):null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product