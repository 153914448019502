import queryString from 'query-string';
import constants from "../constants";
import {setNotFound} from "./BaseAction";
import {decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

function urlParamsString(data) {
    let urlParams = data.join('/').replace('//', '/')
    if (urlParams.slice(-1) === '/') {
        return urlParams.substring(0, urlParams.length - 1)
    } else {
        return urlParams
    }
}

const search = ({value, dispatch}) => {
    return axios.get('/search/' + value).then(resultData => {
		const result = decrypt(resultData.data);
        const payload = result.data.shop.search;
        dispatch({
            payload,
            type: constants.SHOP_SEARCH
        });
    });
}

const clearSearch = ({dispatch}) => {
    const payload = {};
    dispatch( {type: constants.SHOP_SEARCH, payload: payload} );
}

const getShop = ({brand, gen, cat, subcat, sort, color, discount, size, min_price, max_price, gender, search, attr, page, dispatch}) => {
    const urlParams = [gen, cat, subcat];
    const getParams = {
		'brand': brand,
        'sort': sort,
        'color': color,
        'discount': discount,
        'size': size,
        'min_price': min_price,
        'max_price': max_price,
        'gender': gender,
        'search': search,
        'attr': attr
    };

	if(page) {
        getParams['page'] = page;
    }

    return axios.get('/shop/products/' + urlParamsString(urlParams) + '?' + queryString.stringify(getParams)).then(resultData => {
        const result = decrypt(resultData.data);
        if(result.success){
            const payload = result.data.shop;
            setNotFound(result.success, dispatch);
            dispatch({
                payload,
                type: constants.GET_SHOP
            });
        }else{
            setNotFound(result.success, dispatch);
            dispatch({
                type: constants.GET_SHOP_ERROR
            });
        }
    })
};


const getProduct = ({slug, dispatch}) => {
    return axios.get('/product/' + slug).then(resultData => {
        const result = decrypt(resultData.data);
        const payload = result.data.shop;
        setNotFound(result.success, dispatch);
        dispatch({
            payload,
            type: constants.GET_PRODUCT
        });
    })
};

const getProductById = ({id, dispatch}) => {
    return axios.get('/product/ajax/' + id).then(result => {
        const payload = decrypt(result.data);
        dispatch({
            payload,
            type: constants.GET_AJAX_PRODUCT
        });
    })
};

const getSidebar = ({brand, gen, cat, subcat, sort, color, discount, size, min_price, max_price, gender, search, attr, dispatch}) => {
    const urlParams = [gen, cat, subcat];
    const getParams = {
		'brand': brand,
        'sort': sort,
        'color': color,
        'discount': discount,
        'size': size,
        'min_price': min_price,
        'max_price': max_price,
        'gender': gender,
        'search': search,
        'attr': attr
    };

    return axios.get('/shop/sidebar/' + urlParamsString(urlParams) + '?' + queryString.stringify(getParams)).then(resultData => {
        const result = decrypt(resultData.data);

        if(result.success){
            const payload = result.data.shop;
            dispatch({
                payload,
                type: constants.GET_SHOP_SIDEBAR
            });
        }else{
            dispatch({
                type: constants.GET_SHOP_ERROR
            });
        }
    })
};

export {
    search,
    clearSearch,
    getShop,
    getProduct,
	getProductById,
    getSidebar
};