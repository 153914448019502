import constants from "../constants";

const shopState = {
	description: '',
    status: true,
    loadStatus: true,
    search: {},
    currentPage: 1,
    pageCount: 1,
    limit: 20,
    offset: 0,
    products: [],
    productSort: [],
    total: 0,
    discounts: [],
    sizes: [],
    colors: [],
    genders: [],
    collections: [],
    minPrice: 0,
    maxPrice: 0,
    product: {},
    breadcrumbs: {},
    boxes: {},
    related: {},
	ajax: {},
	seo: {},
    ssr: true
};

const ShopReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
		case constants.LOAD_STATUS:
            return {
                ...state,
                loadStatus: false,
            }
        case constants.SHOP_SEARCH:
            return {
                ...state,
                search: payload
            }
        case constants.GET_PRODUCT: {
            return {
                ...state,
                product: payload.product,
                breadcrumbs: payload.breadcrumbs,
                boxes: payload.boxes,
                related: payload.related
            };
        }
		case constants.GET_AJAX_PRODUCT: {
            return {
                ...state,
                ajax: payload.data.data.product
            };
        }
        case constants.GET_SHOP: {
            return {
                ...state,
				seo: payload.seo,
				description: payload.description,
                status: true,
                products: payload.products,
                productSort: payload.productSort,
                currentPage: payload.currentPage,
                pageCount: payload.pageCount,
                total: payload.total,
				offset: 0
            };
        }
		case constants.GET_SHOP_BRAND: {
            return {
                ...state,
                brand: payload.brand,
                seo: payload.seo,
                description: payload.description,
                status: true,
                products: payload.products,
                productSort: payload.productSort,
                total: payload.total,
                offset: 0
            };
        }
        case constants.GET_SHOP_ERROR: {
            return {
                ...state,
                status: false
            };
        }
        case constants.GET_SHOP_MORE: {
            return {
                ...state,
                loadStatus: true,
                offset: payload.offset,
                products: state.products.concat(payload.products),
                total: payload.total
            };
        }
        case constants.GET_SHOP_SIDEBAR: {
            return {
                ...state,
                status: true,
                productSort: payload.productSort,
                genders: payload.genders,
                collections: payload.collections,
                discounts: payload.discounts,
                minPrice: payload.minPrice,
                maxPrice: payload.maxPrice,
                colors: payload.colors,
                sizes: payload.sizes
            };
        }
        default:
            return {...state};
    }
};

export {
    shopState,
    ShopReducer
};