import React from "react";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom'
import styles from './index.module.scss';

const Breadcrambs = (props) => {
    const {t} = useTranslation();

    return (
        <>
        {Object.keys(props.data).length > 0 ? (
            <nav className={styles.nav}>
                <ul className={styles.nav_items}>
                    <li className={styles.nav_items_item}>
                        <Link className={styles.nav_items_item_link} to={'/' + props.lang}>{t("home")}</Link>
                    </li>
                    <li className={styles.nav_items_item}>
                        <Link className={styles.nav_items_item_link}
                              to={'/' + props.lang + '/shop/' + props.data.gen.slug}>{props.data.gen['title_' + props.lang]}</Link>
                    </li>
                    <li className={styles.nav_items_item}>
                        <Link className={styles.nav_items_item_link}
                              to={'/' + props.lang + '/shop/' + props.data.gen.slug + '/' + props.data.cat.parent.slug}>{props.data.cat.parent['title_' + props.lang]}</Link>
                    </li>
                    <li className={styles.nav_items_item}>
                        <Link className={styles.nav_items_item_link}
                              to={'/' + props.lang + '/shop/' + props.data.gen.slug + '/' + props.data.cat.parent.slug + '/' + props.data.cat.slug}>{props.data.cat['title_' + props.lang]}</Link>
                    </li>
                    <li className={styles.nav_items_item}>
                        <span className={styles.nav_items_item_link}>{props.title}</span>
                    </li>
                </ul>
            </nav>
        ) : null}
        </>
    );
}

export default Breadcrambs