import React, {useRef, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDataState} from "../../../state/context";
import {useDataDispatch} from "../../../state/context";
import {recountCart} from "../../../state/actions/CartAction";
import HomeIcon from '../../../ui/icons/home';
import SearchIcon from '../../../ui/icons/search';
import CartIcon from '../../../ui/icons/cart';
import SettingsIcon from '../../../ui/icons/settings';
import Settings from  '../../elements/mobile/footer/settings/index';
import Search from  '../../elements/mobile/footer/search/index';
import Cart from  '../../elements/mobile/footer/cart/index';
import styles from './index.module.scss';

const MobileFooter = () => {
    const dispatch = useDataDispatch();
    const {base, shop, cart} = useDataState();
    const count = Object.keys(cart.products).length + Object.keys(cart.certificates).length;
    const [settings, setSettings] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [cartToggle, setCartToggle] = useState(false);
    const settingsBlock = useRef(null);
    const searchBlock = useRef(null);
    const cartBlock = useRef(null);

    const toggleSettings = () => {
        if (settings) setSettings(false);
        else setSettings(true);

        setSearchToggle(false);
        setCartToggle(false);
    }

    const toggleSearch = () => {
        if (searchToggle) setSearchToggle(false);
        else setSearchToggle(true);

        setSettings(false);
        setCartToggle(false);
    }

    const toggleCart = () => {
        if (cartToggle) setCartToggle(false);
        else setCartToggle(true);

        setSearchToggle(false);
        setSettings(false);
    }

    const handleClickOutside = (event) => {
        if (settingsBlock.current && !settingsBlock.current.contains(event.target)) {
            setSettings(false);
        }

        if (searchBlock.current && !searchBlock.current.contains(event.target)) {
            setSearchToggle(false);
        }

        if (cartBlock.current && !cartBlock.current.contains(event.target)) {
            setCartToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        recountCart(dispatch);
    }, [dispatch]);

    return (
        <div className={styles.footer}>
            <ul className={styles.footer_items}>
                <li className={styles.footer_items_item}>
                    <Link to={"/" + base.currentLang}>
                        <HomeIcon name="discount" color="#fff" size={22}/>
                    </Link>
                </li>
                <li ref={searchBlock} className={styles.footer_items_item}>
                    <span onClick={toggleSearch}>
                        <SearchIcon name="discount" color="#fff" size={22}/>
                    </span>
                    <Search search={shop.search} currentLang={base.currentLang} searchToggle={searchToggle}
                            setSearchToggle={setSearchToggle}/>
                </li>
                <li ref={cartBlock} className={styles.footer_items_item}>
                    <span className={styles.footer_items_item_cart}>{count}</span>
                    <span onClick={toggleCart}>
                        <CartIcon name="discount" color="#fff" size={22}/>
                    </span>
                    <Cart cartToggle={cartToggle} setCartToggle={setCartToggle} count={count} products={cart.products}
                          certificates={cart.certificates} currentLang={base.currentLang}/>
                </li>
                <li ref={settingsBlock} className={styles.footer_items_item}>
                    <span onClick={toggleSettings}>
                        <SettingsIcon name="discount" color="#fff" size={22}/>
                    </span>
                    <Settings settings={settings} setSettings={setSettings} currentLang={base.currentLang}
                              langs={base.langs} headerPages={base.headerPages} footerMenu={base.footerMenu}/>
                </li>
            </ul>
        </div>
    );
}

export default MobileFooter