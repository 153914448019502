const CalcHeight = (id, height) => {
    if(typeof document !== 'undefined') {
        const element = document.getElementById(id);

        return {height: 'calc(' + height + ' - ' + element.offsetHeight + 'px)'};
    } else {
        return {height: '100%'};
    }
};

export default CalcHeight;