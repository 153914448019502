import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import constants from "../../state/constants";
import styles from './index.module.scss';

const SizesPage = (props) => {
    const {t} = useTranslation();
    const [menuTab, setMenuTab] = useState(props.params.gen);
    const [subMenuTab, setSubMenuTab] = useState(props.params.cat);

    const tabsMenuHandler = (e) => {
        setMenuTab(e.target.dataset.gen)
    }

    const tabsSubMenuHandler = (e) => {
        setSubMenuTab(e.target.dataset.cat)
    }

    return (
        <div className={`${styles.support_content_wrapper} ${props.scroll ? styles.support_content_wrapper_scroll:''}`}>

            <hr className={styles.support_content_border}/>

            <ul className={styles.support_content_tabs}>
                <li onClick={tabsMenuHandler} className={`${styles.support_content_tabs_item} ${menuTab === 'female' ? styles.active : ''}`} data-gen="female">{t('size-female')}</li>
                <li onClick={tabsMenuHandler} className={`${styles.support_content_tabs_item} ${menuTab === 'male' || menuTab === 'unisex' ? styles.active : ''}`} data-gen="male">{t('size-male')}</li>
                <li onClick={tabsMenuHandler} className={`${styles.support_content_tabs_item} ${menuTab === 'children' || menuTab === 'boys' || menuTab === 'girls' ? styles.active : ''}`} data-gen="children">{t('size-children')}</li>
            </ul>

            <hr className={styles.support_content_border}/>

            <ul className={styles.support_content_sub_tabs}>
                <li onClick={tabsSubMenuHandler} className={`${styles.support_content_sub_tabs_item} ${['apparel', 'winter', 'sport'].includes(subMenuTab) ? styles.active : ''}`} data-cat="apparel">{t('size-apparel')}</li>
                <li onClick={tabsSubMenuHandler} className={` ${styles.support_content_sub_tabs_item} ${subMenuTab === 'footwear' ? styles.active : ''}`} data-cat="footwear">{t('size-footwear')}</li>
                <li onClick={tabsSubMenuHandler} className={`${styles.support_content_sub_tabs_item} ${subMenuTab === 'accessories' ? styles.active : ''}`} data-cat="accessories">{t('size-accessories')}</li>
            </ul>

            <hr className={styles.support_content_border}/>

            <div className={`${styles.support_content_tab} ${['apparel', 'winter', 'sport'].includes(subMenuTab) && menuTab === 'female' ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t("short_size")}</th>
                        <th>{t("short_size_eu")}</th>
                        <th>UK</th>
                        <th>US</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>38-40</td>
                        <td>32</td>
                        <td>6</td>
                        <td>XXS</td>
                    </tr>
                    <tr>
                        <td>40-42</td>
                        <td>34</td>
                        <td>8</td>
                        <td>XS</td>
                    </tr>
                    <tr>
                        <td>42-44</td>
                        <td>36</td>
                        <td>10</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>44-46</td>
                        <td>38</td>
                        <td>12</td>
                        <td>M</td>
                    </tr>
                    <tr>
                        <td>46-48</td>
                        <td>40</td>
                        <td>14</td>
                        <td>L</td>
                    </tr>
                    <tr>
                        <td>48-50</td>
                        <td>42</td>
                        <td>16</td>
                        <td>XL</td>
                    </tr>
                    <tr>
                        <td>50-52</td>
                        <td>44</td>
                        <td>18</td>
                        <td>XXL</td>
                    </tr>
                    <tr>
                        <td>52-54</td>
                        <td>46</td>
                        <td>20</td>
                        <td>3XL</td>
                    </tr>
                    </tbody>
                </table>
                <table className={styles.support_content_tab_measurements}>
                    <tbody>
                    <tr>
                        <td rowSpan="8"><img alt="" src={constants.API_BASE_URL + '/images/woman-masure.png'}/></td>
                        <td><strong>1 – {t('bust')}</strong></td>
                        <td>{t('female-bust')}</td>
                    </tr>
                    <tr>
                        <td><strong>2 – {t('waist')}</strong></td>
                        <td>{t('female-waist')}</td>
                    </tr>
                    <tr>
                        <td><strong>3 – {t('hips')}</strong></td>
                        <td>{t('female-hips')}</td>
                    </tr>
                    <tr>
                        <td><strong>4 – {t('side-seam')}</strong></td>
                        <td>{t('female-side-seam')}</td>
                    </tr>
                    <tr>
                        <td><strong>5 - {t('thigh')}</strong></td>
                        <td>{t('female-thigh')}</td>
                    </tr>
                    <tr>
                        <td><strong>6 - {t('in-seam')}</strong></td>
                        <td>{t('female-in-seam')}</td>
                    </tr>
                    <tr>
                        <td><strong>7 - {t('sleeve-length')}</strong></td>
                        <td>{t('female-sleeve-length')}</td>
                    </tr>
                    <tr>
                        <td><strong>8 - {t('shoulder-width')}</strong>&nbsp;</td>
                        <td>{t('female-shoulder-width')}</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                    <tr>
                        <th><span>{t('raz')}</span></th>
                        <th>{t('chest')}</th>
                        <th>{t('waist')}</th>
                        <th>{t('hips')}</th>
                        <th>{t('in-seam')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>40-42</td>
                        <td>82</td>
                        <td>65</td>
                        <td>93</td>
                        <td>78</td>
                    </tr>
                    <tr>
                        <td>42-44</td>
                        <td>86</td>
                        <td>68</td>
                        <td>96</td>
                        <td>78.5</td>
                    </tr>
                    <tr>
                        <td>44-46</td>
                        <td>91</td>
                        <td>74</td>
                        <td>101</td>
                        <td>79</td>
                    </tr>
                    <tr>
                        <td>46-48</td>
                        <td>96</td>
                        <td>80</td>
                        <td>106</td>
                        <td>79.5</td>
                    </tr>
                    <tr>
                        <td>48-50</td>
                        <td>101</td>
                        <td>86</td>
                        <td>112</td>
                        <td>80</td>
                    </tr>
                    <tr>
                        <td>50-52</td>
                        <td>107</td>
                        <td>92</td>
                        <td>118</td>
                        <td>80.5</td>
                    </tr>
                    <tr>
                        <td>52-54</td>
                        <td>113</td>
                        <td>98</td>
                        <td>124</td>
                        <td>81</td>
                    </tr>
                    <tr>
                        <td>54-56</td>
                        <td>110-115</td>
                        <td>95-100</td>
                        <td>121-126</td>
                        <td>81.5</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={`${styles.support_content_tab} ${subMenuTab === 'footwear' && menuTab === 'female' ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>UK</th>
                        <th>{t('short_size_eu')}</th>
                        <th>US</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>33</td>
                        <td>1.5</td>
                        <td>34</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>33.5</td>
                        <td>2</td>
                        <td>34.5</td>
                        <td>4.5</td>
                    </tr>
                    <tr>
                        <td>34</td>
                        <td>2.5</td>
                        <td>35</td>
                        <td>5</td>
                    </tr>
                    <tr>
                        <td>34.5</td>
                        <td>3</td>
                        <td>35.5</td>
                        <td>5.5</td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>3.5</td>
                        <td>36</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>4</td>
                        <td>37</td>
                        <td>6.5</td>
                    </tr>
                    <tr>
                        <td>36.5</td>
                        <td>4.5</td>
                        <td>37.5</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>37</td>
                        <td>5</td>
                        <td>38</td>
                        <td>7.5</td>
                    </tr>
                    <tr>
                        <td>37.5</td>
                        <td>5.5</td>
                        <td>38.5</td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>6</td>
                        <td>39</td>
                        <td>8.5</td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>6.5</td>
                        <td>40</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>39.5</td>
                        <td>7</td>
                        <td>40.5</td>
                        <td>9.5</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>7.5</td>
                        <td>41</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>8</td>
                        <td>42</td>
                        <td>10.5</td>
                    </tr>
                    <tr>
                        <td>41.5</td>
                        <td>8.5</td>
                        <td>42.5</td>
                        <td>11</td>
                    </tr>
                    </tbody>
                </table>

                <h3>{t('size_stelke')}</h3>
                <hr className={styles.slide__model_content__body_border}/>
                <table>
                    <thead>
                    <tr>
                        <th>US / NZ</th>
                        <th>HEEL - TOE</th>
                        <th>UK</th>
                        <th>EUROPE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>5</td>
                        <td>21.5 cm</td>
                        <td>2.5</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>5.5</td>
                        <td>22 cm</td>
                        <td>3</td>
                        <td>35.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>22.5 cm</td>
                        <td>3.5</td>
                        <td>36</td>
                    </tr>
                    <tr>
                        <td>6.5</td>
                        <td>23 cm</td>
                        <td>4</td>
                        <td>37</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>23.5 cm</td>
                        <td>4.5</td>
                        <td>37.5</td>
                    </tr>
                    <tr>
                        <td>7.5</td>
                        <td>24 cm</td>
                        <td>5</td>
                        <td>38</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>24.5 cm</td>
                        <td>5.5</td>
                        <td>38.5</td>
                    </tr>
                    <tr>
                        <td>8.5</td>
                        <td>25 cm</td>
                        <td>6</td>
                        <td>39</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>25.5 cm</td>
                        <td>6.5</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>9.5</td>
                        <td>26</td>
                        <td>7</td>
                        <td>40.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>26.5 cm</td>
                        <td>7.5</td>
                        <td>41</td>
                    </tr>
                    <tr>
                        <td>10.5</td>
                        <td>27 cm</td>
                        <td>8</td>
                        <td>42</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>27.5 cm</td>
                        <td>8.5</td>
                        <td>42.5</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={`${styles.support_content_tab} ${subMenuTab === 'accessories' && menuTab === 'female' ? styles.active : ''}`}>
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>{t('short_size_sport')}</th>
                        <th>UK</th>
                        <th>{t('cm')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>31-34</td>
                        <td>1</td>
                        <td>12-1.5</td>
                        <td>19-22</td>
                    </tr>
                    <tr>
                        <td>35-38</td>
                        <td>2</td>
                        <td>2.5-5</td>
                        <td>23-25</td>
                    </tr>
                    <tr>
                        <td>39-42</td>
                        <td>3</td>
                        <td>6-8</td>
                        <td>26-28</td>
                    </tr>
                    <tr>
                        <td>43-46</td>
                        <td>4</td>
                        <td>9-11</td>
                        <td>29-31</td>
                    </tr>
                    <tr>
                        <td>47-50</td>
                        <td>5</td>
                        <td>12-24</td>
                        <td>32-34</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={`${styles.support_content_tab} ${['apparel', 'winter', 'sport'].includes(subMenuTab) && (menuTab === 'male' || menuTab === 'unisex') ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>{t('short_size_eu')}</th>
                        <th>UK</th>
                        <th>US</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>40-42</td>
                        <td>38</td>
                        <td>XXS</td>
                        <td>XXS</td>
                    </tr>
                    <tr>
                        <td>42-44</td>
                        <td>40/42</td>
                        <td>XS</td>
                        <td>XS</td>
                    </tr>
                    <tr>
                        <td>44-46</td>
                        <td>44/46</td>
                        <td>S</td>
                        <td>S</td>
                    </tr>
                    <tr>
                        <td>46-48</td>
                        <td>48/50</td>
                        <td>M</td>
                        <td>M</td>
                    </tr>
                    <tr>
                        <td>48-50</td>
                        <td>52/54</td>
                        <td>L</td>
                        <td>L</td>
                    </tr>
                    <tr>
                        <td>50-52</td>
                        <td>56/58</td>
                        <td>XL</td>
                        <td>XL</td>
                    </tr>
                    <tr>
                        <td>52-54</td>
                        <td>60/62</td>
                        <td>XXL</td>
                        <td>XXL</td>
                    </tr>
                    <tr>
                        <td>54-56</td>
                        <td>64/66</td>
                        <td>3XL</td>
                        <td>3XL</td>
                    </tr>
                    </tbody>
                </table>
                <table className={styles.support_content_tab_measurements}>
                    <tbody>
                    <tr>
                        <td rowSpan="8"><img alt="" src={constants.API_BASE_URL + '/images/man-masure.jpg'} /></td>
                        <td><strong>1 – {t('chest')}</strong></td>
                        <td>{t('male-chest')}</td>
                    </tr>
                    <tr>
                        <td><strong>2 – {t('waist')}</strong></td>
                        <td>{t('male-waist')}</td>
                    </tr>
                    <tr>
                        <td><strong>3 – {t('hips')}</strong></td>
                        <td>{t('male-hips')}</td>
                    </tr>
                    <tr>
                        <td><strong>4 – {t('side-seam')}</strong></td>
                        <td>{t('male-side-seam')}</td>
                    </tr>
                    <tr>
                        <td><strong>5 - {t('thigh')}</strong></td>
                        <td>{t('male-thigh')}</td>
                    </tr>
                    <tr>
                        <td><strong>6 - {t('in-seam')}</strong></td>
                        <td>{t('male-in-seam')}</td>
                    </tr>
                    <tr>
                        <td><strong>7 - {t('sleeve-length')}</strong></td>
                        <td>{t('male-sleeve-length')}</td>
                    </tr>
                    <tr>
                        <td><strong>8 - {t('shoulder-width')}</strong>&nbsp;</td>
                        <td>{t('male-shoulder-width')}</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                    <tr>
                        <th><span>{t('raz')}</span></th>
                        <th>{t('chest')}</th>
                        <th>{t('waist')}</th>
                        <th>{t('hips')}</th>
                        <th>{t('in-seam')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>38-40</td>
                        <td>71-78</td>
                        <td>67-72</td>
                        <td>77-83</td>
                        <td>78</td>
                    </tr>
                    <tr>
                        <td>40-42</td>
                        <td>76</td>
                        <td>70</td>
                        <td>81</td>
                        <td>79</td>
                    </tr>
                    <tr>
                        <td>42-44</td>
                        <td>84</td>
                        <td>76</td>
                        <td>88</td>
                        <td>80</td>
                    </tr>
                    <tr>
                        <td>44-46</td>
                        <td>92</td>
                        <td>82</td>
                        <td>95</td>
                        <td>81</td>
                    </tr>
                    <tr>
                        <td>46-48</td>
                        <td>100</td>
                        <td>88</td>
                        <td>102</td>
                        <td>82</td>
                    </tr>
                    <tr>
                        <td>48-50</td>
                        <td>108</td>
                        <td>96</td>
                        <td>110</td>
                        <td>83</td>
                    </tr>
                    <tr>
                        <td>50-52</td>
                        <td>116</td>
                        <td>104</td>
                        <td>118</td>
                        <td>84</td>
                    </tr>
                    <tr>
                        <td>52-54</td>
                        <td>124</td>
                        <td>114</td>
                        <td>126</td>
                        <td>85</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={`${styles.support_content_tab} ${subMenuTab === 'footwear' && (menuTab === 'male' || menuTab === 'unisex') ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>UK</th>
                        <th>{t('short_size')}</th>
                        <th>US</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>37</td>
                        <td>5</td>
                        <td>38</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>37.5</td>
                        <td>5.5</td>
                        <td>38.5</td>
                        <td>6.5</td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>6</td>
                        <td>39</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>6.5</td>
                        <td>40</td>
                        <td>7.5</td>
                    </tr>
                    <tr>
                        <td>39.5</td>
                        <td>7</td>
                        <td>40.5</td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>7.5</td>
                        <td>41</td>
                        <td>8.5</td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>8</td>
                        <td>42</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>41.5</td>
                        <td>8.5</td>
                        <td>42.5</td>
                        <td>9.5</td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>9</td>
                        <td>43</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>43</td>
                        <td>9.5</td>
                        <td>44</td>
                        <td>10.5</td>
                    </tr>
                    <tr>
                        <td>43.5</td>
                        <td>10</td>
                        <td>44.5</td>
                        <td>11</td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>10.5</td>
                        <td>45</td>
                        <td>11.5</td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>11</td>
                        <td>46</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>45.5</td>
                        <td>11.5</td>
                        <td>46.5</td>
                        <td>12.5</td>
                    </tr>
                    <tr>
                        <td>46</td>
                        <td>12</td>
                        <td>47</td>
                        <td>13</td>
                    </tr>
                    <tr>
                        <td>47.5</td>
                        <td>13</td>
                        <td>48.5</td>
                        <td>14</td>
                    </tr>
                    <tr>
                        <td>48.5</td>
                        <td>14</td>
                        <td>49.5</td>
                        <td>15</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>15</td>
                        <td>51</td>
                        <td>16</td>
                    </tr>
                    </tbody>
                </table>
                <h3>{t('size_stelke')}</h3>
                <hr className={styles.support_content_border}/>
                <table>
                    <thead>
                    <tr>
                        <th>US / NZ</th>
                        <th>HEEL - TOE</th>
                        <th>UK</th>
                        <th>EUROPE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3.5</td>
                        <td>21.5 cm</td>
                        <td>2.5</td>
                        <td>35</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>22 cm</td>
                        <td>3</td>
                        <td>36</td>
                    </tr>
                    <tr>
                        <td>4.5</td>
                        <td>22.5 cm</td>
                        <td>3.5</td>
                        <td>36.5</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>23 cm</td>
                        <td>4</td>
                        <td>37</td>
                    </tr>
                    <tr>
                        <td>5.5</td>
                        <td>23.5 cm</td>
                        <td>4.5</td>
                        <td>37.5</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>24 cm</td>
                        <td>5</td>
                        <td>38</td>
                    </tr>
                    <tr>
                        <td>6.5</td>
                        <td>24.5 cm</td>
                        <td>5.5</td>
                        <td>38.5</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>25 cm</td>
                        <td>6</td>
                        <td>39</td>
                    </tr>
                    <tr>
                        <td>7.5</td>
                        <td>25.5 cm</td>
                        <td>6.5</td>
                        <td>40</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>26</td>
                        <td>7</td>
                        <td>40.5</td>
                    </tr>
                    <tr>
                        <td>8.5</td>
                        <td>26.5 cm</td>
                        <td>7.5</td>
                        <td>41</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>27 cm</td>
                        <td>8</td>
                        <td>42</td>
                    </tr>
                    <tr>
                        <td>9.5</td>
                        <td>27.5 cm</td>
                        <td>8.5</td>
                        <td>42.5</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>28 cm</td>
                        <td>9</td>
                        <td>43</td>
                    </tr>
                    <tr>
                        <td>10.5</td>
                        <td>28.5 cm</td>
                        <td>9.5</td>
                        <td>44</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>29 cm</td>
                        <td>10</td>
                        <td>44.5</td>
                    </tr>
                    <tr>
                        <td>11.5</td>
                        <td>29.5 cm</td>
                        <td>10.5</td>
                        <td>45</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>30 cm</td>
                        <td>11</td>
                        <td>46</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>31 cm</td>
                        <td>12</td>
                        <td>47</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>32 cm</td>
                        <td>13</td>
                        <td>48.5</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>33 cm</td>
                        <td>14</td>
                        <td>49.5</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={`${styles.support_content_tab} ${subMenuTab === 'accessories' && (menuTab === 'male' || menuTab === 'unisex') ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>{t('short_size_eu')}</th>
                        <th>UK</th>
                        <th>{t('cm')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>31-34</td>
                        <td>1</td>
                        <td>12-1.5</td>
                        <td>19-22</td>
                    </tr>
                    <tr>
                        <td>35-38</td>
                        <td>2</td>
                        <td>2.5-5</td>
                        <td>23-25</td>
                    </tr>
                    <tr>
                        <td>39-42</td>
                        <td>3</td>
                        <td>6-8</td>
                        <td>26-28</td>
                    </tr>
                    <tr>
                        <td>43-46</td>
                        <td>4</td>
                        <td>9-11</td>
                        <td>29-31</td>
                    </tr>
                    <tr>
                        <td>47-50</td>
                        <td>5</td>
                        <td>12-24</td>
                        <td>32-34</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={`${styles.support_content_tab} ${['apparel', 'winter', 'sport'].includes(subMenuTab) && menuTab === 'children' ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>US</th>
                        <th>UK</th>
                        <th>UK</th>
                        <th>{t('size_fr')}</th>
                        <th>{t('size_germany')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>62</td>
                        <td>XS</td>
                        <td>2/4</td>
                        <td>2-4M</td>
                        <td>3</td>
                        <td>62</td>
                    </tr>
                    <tr>
                        <td>68</td>
                        <td>S</td>
                        <td>4/6</td>
                        <td>4-6M</td>
                        <td>6</td>
                        <td>68</td>
                    </tr>
                    <tr>
                        <td>74</td>
                        <td>M</td>
                        <td>6/9</td>
                        <td>6-9M</td>
                        <td>12</td>
                        <td>74</td>
                    </tr>
                    <tr>
                        <td>80</td>
                        <td>L</td>
                        <td>9/12</td>
                        <td>9-12M</td>
                        <td>18</td>
                        <td>80</td>
                    </tr>
                    <tr>
                        <td>86</td>
                        <td>XL</td>
                        <td>12/18</td>
                        <td>12-18M</td>
                        <td>2A</td>
                        <td>86</td>
                    </tr>
                    <tr>
                        <td>92</td>
                        <td>2T</td>
                        <td>18/24</td>
                        <td>1-2Y</td>
                        <td>2A1/2</td>
                        <td>92</td>
                    </tr>
                    <tr>
                        <td>98</td>
                        <td>3T</td>
                        <td>24/30</td>
                        <td>2-3Y</td>
                        <td>3A</td>
                        <td>98</td>
                    </tr>
                    <tr>
                        <td>104</td>
                        <td>4T</td>
                        <td>36/42</td>
                        <td>3-4Y</td>
                        <td>4A</td>
                        <td>104</td>
                    </tr>
                    </tbody>
                </table>

                <table className={styles.support_content_tab_measurements}>
                    <tbody>
                    <tr>
                        <td><strong>{t('height')}</strong></td>
                        <td>{t('height-note')}</td>
                    </tr>
                    <tr>
                        <td><strong>{t('chest')}</strong></td>
                        <td>{t('chest-note')}</td>
                    </tr>
                    <tr>
                        <td><strong>{t('waist')}</strong></td>
                        <td>{t('waist-note')}</td>
                    </tr>
                    <tr>
                        <td><strong>{t('hips')}</strong></td>
                        <td>{t('hips-note')}</td>
                    </tr>
                    </tbody>
                </table>

                <table>
                    <thead>
                    <tr>
                        <th>{t('raz')}</th>
                        <th>{t('age')}</th>
                        <th>{t('height')}</th>
                        <th>{t('chest')}</th>
                        <th>{t('waist')}</th>
                        <th>{t('hips')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>62</td>
                        <td>2-4M</td>
                        <td>57-62</td>
                        <td>41-43</td>
                        <td>41-43</td>
                        <td>42-44</td>
                    </tr>
                    <tr>
                        <td>68</td>
                        <td>4-6M</td>
                        <td>63-68</td>
                        <td>44-46</td>
                        <td>44-45</td>
                        <td>45-47</td>
                    </tr>
                    <tr>
                        <td>74</td>
                        <td>6-9M</td>
                        <td>69-74</td>
                        <td>47-49</td>
                        <td>45-47</td>
                        <td>48-50</td>
                    </tr>
                    <tr>
                        <td>80</td>
                        <td>9-12M</td>
                        <td>75-80</td>
                        <td>50-52</td>
                        <td>48-49</td>
                        <td>51-53</td>
                    </tr>
                    <tr>
                        <td>86</td>
                        <td>12-18M</td>
                        <td>81-86</td>
                        <td>53</td>
                        <td>50</td>
                        <td>54-55</td>
                    </tr>
                    <tr>
                        <td>92</td>
                        <td>1-2Y</td>
                        <td>87-92</td>
                        <td>54</td>
                        <td>51</td>
                        <td>56-57</td>
                    </tr>
                    <tr>
                        <td>98</td>
                        <td>2-3Y</td>
                        <td>93-98</td>
                        <td>55</td>
                        <td>52</td>
                        <td>58-59</td>
                    </tr>
                    <tr>
                        <td>104</td>
                        <td>3-4Y</td>
                        <td>99-104</td>
                        <td>56</td>
                        <td>53</td>
                        <td>60-61</td>
                    </tr>
                    </tbody>
                </table>
                <hr className={styles.support_content_border}/>
                <h3>{t('children-size-table')}</h3>
                <hr className={styles.support_content_border}/>
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>US</th>
                        <th>UK</th>
                        <th>UK</th>
                        <th>{t('size_fr')}</th>
                        <th>{t('size_germany')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>104</td>
                        <td>4T</td>
                        <td>3/4</td>
                        <td>3-4Y</td>
                        <td>4</td>
                        <td>104</td>
                    </tr>
                    <tr>
                        <td>116</td>
                        <td>XS</td>
                        <td>5/6</td>
                        <td>5-6Y</td>
                        <td>6</td>
                        <td>116</td>
                    </tr>
                    <tr>
                        <td>128</td>
                        <td>S</td>
                        <td>24/26</td>
                        <td>7-8Y</td>
                        <td>8</td>
                        <td>128</td>
                    </tr>
                    <tr>
                        <td>140</td>
                        <td>M</td>
                        <td>26/28</td>
                        <td>9-10Y</td>
                        <td>10</td>
                        <td>140</td>
                    </tr>
                    <tr>
                        <td>152</td>
                        <td>L</td>
                        <td>30/32</td>
                        <td>11-12Y</td>
                        <td>12</td>
                        <td>152</td>
                    </tr>
                    <tr>
                        <td>164</td>
                        <td>XL</td>
                        <td>32/34</td>
                        <td>13-14Y</td>
                        <td>14</td>
                        <td>164</td>
                    </tr>
                    <tr>
                        <td>176</td>
                        <td>XXL</td>
                        <td>34/36</td>
                        <td>15-16Y</td>
                        <td>16</td>
                        <td>176</td>
                    </tr>
                    </tbody>
                </table>
                <hr className={styles.support_content_border}/>
                <table>
                    <thead>
                    <tr>
                        <th>{t('raz')}</th>
                        <th>{t('age')}</th>
                        <th>{t('height')}</th>
                        <th>{t('chest')}</th>
                        <th colSpan="2">{t('boys')}</th>
                        <th colSpan="2">{t('girls')}</th>
                    </tr>
                    <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>{t('waist')}</th>
                        <th>{t('hips')}</th>
                        <th>{t('waist')}</th>
                        <th>{t('hips')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>104</td>
                        <td>3-4Y</td>
                        <td>99-104</td>
                        <td>55-56</td>
                        <td>52-53</td>
                        <td>56-60</td>
                        <td>52-53</td>
                        <td>56-60</td>
                    </tr>
                    <tr>
                        <td>116</td>
                        <td>5-6Y</td>
                        <td>105-116</td>
                        <td>57-60</td>
                        <td>54-56</td>
                        <td>61-64</td>
                        <td>54-56</td>
                        <td>61-64</td>
                    </tr>
                    <tr>
                        <td>128</td>
                        <td>7-8Y</td>
                        <td>117-128</td>
                        <td>61-65</td>
                        <td>57-60</td>
                        <td>65-70</td>
                        <td>57-59</td>
                        <td>65-70</td>
                    </tr>
                    <tr>
                        <td>140</td>
                        <td>9-10Y</td>
                        <td>129-140</td>
                        <td>66-71</td>
                        <td>61-64</td>
                        <td>71-76</td>
                        <td>60-62</td>
                        <td>71-76</td>
                    </tr>
                    <tr>
                        <td>152</td>
                        <td>11-12Y</td>
                        <td>141-152</td>
                        <td>72-77</td>
                        <td>65-68</td>
                        <td>77-82</td>
                        <td>63-65</td>
                        <td>77-83</td>
                    </tr>
                    <tr>
                        <td>164</td>
                        <td>13-14Y</td>
                        <td>153-164</td>
                        <td>78-83</td>
                        <td>69-72</td>
                        <td>83-88</td>
                        <td>66-68</td>
                        <td>84-90</td>
                    </tr>
                    <tr>
                        <td>176</td>
                        <td>15-16Y</td>
                        <td>165-176</td>
                        <td>84-89</td>
                        <td>73-76</td>
                        <td>89-94</td>
                        <td>69-71</td>
                        <td>91-97</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={`${styles.support_content_tab} ${subMenuTab === 'footwear' && menuTab === 'children' ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>us</th>
                        <th>uk</th>
                        <th>fr</th>
                        <th>de</th>
                        <th>jp</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3</td>
                        <td>2</td>
                        <td>18</td>
                        <td>18</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>3</td>
                        <td>19</td>
                        <td>19</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>4</td>
                        <td>20</td>
                        <td>20</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>5½</td>
                        <td>4½</td>
                        <td>21</td>
                        <td>21</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>5</td>
                        <td>22</td>
                        <td>22</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>6</td>
                        <td>23</td>
                        <td>23</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>7</td>
                        <td>24</td>
                        <td>24</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>8</td>
                        <td>25</td>
                        <td>25</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>9½</td>
                        <td>8½</td>
                        <td>26</td>
                        <td>26</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>9</td>
                        <td>27</td>
                        <td>27</td>
                        <td>–</td>
                    </tr>
                    </tbody>
                </table>

                <hr className={styles.support_content_border}/>
                <table>
                    <thead>
                    <tr>
                        <th>us</th>
                        <th>uk</th>
                        <th>fr</th>
                        <th>de</th>
                        <th>jp</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>11</td>
                        <td>10</td>
                        <td>28</td>
                        <td>28</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>11</td>
                        <td>29</td>
                        <td>29</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>12½</td>
                        <td>11½</td>
                        <td>30</td>
                        <td>30</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>12</td>
                        <td>31</td>
                        <td>31</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>13</td>
                        <td>32</td>
                        <td>32</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>14½</td>
                        <td>13½</td>
                        <td>32½</td>
                        <td>32½</td>
                        <td>–</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>14</td>
                        <td>33</td>
                        <td>33</td>
                        <td>20</td>
                    </tr>
                    <tr>
                        <td>15½</td>
                        <td>14½</td>
                        <td>34</td>
                        <td>34</td>
                        <td>20½</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>15</td>
                        <td>34½</td>
                        <td>34½</td>
                        <td>21</td>
                    </tr>
                    <tr>
                        <td>16½</td>
                        <td>15½</td>
                        <td>35</td>
                        <td>35</td>
                        <td>21½</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>16</td>
                        <td>35½</td>
                        <td>35½</td>
                        <td>22</td>
                    </tr>
                    <tr>
                        <td>17½</td>
                        <td>16½</td>
                        <td>36</td>
                        <td>36</td>
                        <td>22½</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>17</td>
                        <td>37</td>
                        <td>37</td>
                        <td>23</td>
                    </tr>
                    <tr>
                        <td>18½</td>
                        <td>17½</td>
                        <td>37½</td>
                        <td>37½</td>
                        <td>23½</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>18</td>
                        <td>38</td>
                        <td>38</td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td>19½</td>
                        <td>18½</td>
                        <td>38½</td>
                        <td>38½</td>
                        <td>24½</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>19</td>
                        <td>39</td>
                        <td>39</td>
                        <td>25</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={`${styles.support_content_tab} ${subMenuTab === 'accessories' && menuTab === 'children' ? styles.active : ''}`} >
                <table>
                    <thead>
                    <tr>
                        <th>{t('short_size')}</th>
                        <th>{t('short_size_eu')}</th>
                        <th>UK</th>
                        <th>cm</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>31-34</td>
                        <td>1</td>
                        <td>12-1.5</td>
                        <td>19-22</td>
                    </tr>
                    <tr>
                        <td>35-38</td>
                        <td>2</td>
                        <td>2.5-5</td>
                        <td>23-25</td>
                    </tr>
                    <tr>
                        <td>39-42</td>
                        <td>3</td>
                        <td>6-8</td>
                        <td>26-28</td>
                    </tr>
                    <tr>
                        <td>43-46</td>
                        <td>4</td>
                        <td>9-11</td>
                        <td>29-31</td>
                    </tr>
                    <tr>
                        <td>47-50</td>
                        <td>5</td>
                        <td>12-24</td>
                        <td>32-34</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SizesPage