import constants from "../constants";
import Cookies from "../coockies/index";
import StorageService from "../storage/index";
import {messages} from './FormAction';
import {encrypt, decrypt} from "../encrypt";
import axios from "axios";

axios.defaults.baseURL = constants.API_URL;

const showMiniCart = (dispatch) => {
    dispatch({type: constants.SHOW_MINI_CART});
}

const hiddeMiniCart = (dispatch) => {
    dispatch({type: constants.HIDDE_MINI_CART});
}

const setDelivery = (dispatch, deliveryType, regionId, payment) => {
    recountCart(dispatch, deliveryType, regionId, payment);
}

const recountCart = (dispatch, deliveryType = false, regionId = false, payment = false) => {
    const products = StorageService.get('eyJpdiI6IkFwbUtBWmpDbm');
    const certificates = StorageService.get('eyJpdiI6InlrRnE4anBOQn');

    if (products || certificates) {
        const productsData = {
            delivery_type: deliveryType,
            region_id: regionId,
            payment: payment,
            products: (products) ? products:{},
            certificates: (certificates) ? certificates:{},
			token: Cookies.get('token')
        };

        axios.post(constants.API_URL + '/cart/products', {data: encrypt(JSON.stringify(productsData))}).then(resultData => {
            const response = decrypt(resultData.data);
            const payload = {
                products: response.data.products,
                certificates: response.data.certificates,
                baseTotal: response.data.cartData.baseTotal,
                total: response.data.cartData.total,
                sales: response.data.cartData.sales,
                delivery: response.data.cartData.delivery
            };

            dispatch({ payload, type: constants.CART_DATA })
        });
    } else {
        const payload = {
            products: {},
            certificates: {},
            baseTotal: 0,
            total: 0,
            sales: 0,
            delivery: 0
        };

        dispatch({ payload, type: constants.CART_DATA })
    }

}

const productChangeQuantity = ({barcode, quantityMount, dispatch}) => {
    const products = StorageService.get('eyJpdiI6IkFwbUtBWmpDbm');
    products[barcode]['count'] = quantityMount;

    StorageService.set("eyJpdiI6IkFwbUtBWmpDbm", products);
    recountCart(dispatch);
}

const productRemove = ({removeBarcode, dispatch}) => {
    const products = StorageService.get('eyJpdiI6IkFwbUtBWmpDbm')
    delete products[removeBarcode];

    StorageService.set("eyJpdiI6IkFwbUtBWmpDbm", products);
    recountCart(dispatch);
}

const addToCart = ({slug, productSize, quantity, dispatch}) => {
    if (productSize) {
        const cartItems = StorageService.get('eyJpdiI6IkFwbUtBWmpDbm') !== 'undefined' ? StorageService.get('eyJpdiI6IkFwbUtBWmpDbm') : {};

        if (cartItems) {
            if (cartItems[productSize.barcode]) {
                cartItems[productSize.barcode]['count'] = cartItems[productSize.barcode]['count'] + parseInt(quantity);
            } else {
                cartItems[productSize.barcode] = {count: quantity, size: productSize.size, slug: slug}
            }

            StorageService.set("eyJpdiI6IkFwbUtBWmpDbm", cartItems);
        } else {
            const items = {
                [productSize.barcode]: {
                    count: quantity,
                    size: productSize.size,
                    slug: slug
                }
            };
            StorageService.set("eyJpdiI6IkFwbUtBWmpDbm", items);
        }

        recountCart(dispatch);
        showMiniCart(dispatch);
    } else {
        //dispatch({type: constants.ADD_TO_CART_ERROR})
    }
};

const certificateRemove = ({removeBarcode, dispatch}) => {
    const certificates = StorageService.get('eyJpdiI6InlrRnE4anBOQn')
    delete certificates[removeBarcode];

    StorageService.set("eyJpdiI6InlrRnE4anBOQn", certificates);
    recountCart(dispatch);
}

const addToCartCertificat = (form, price, dispatch) => {
    const certificateItems = StorageService.get('eyJpdiI6InlrRnE4anBOQn');
    form.price = (form.price) ? form.price : price;

    if (certificateItems) {
        certificateItems[form.price + form.recipient_name] = form;
        StorageService.set("eyJpdiI6InlrRnE4anBOQn", certificateItems);
    } else {
        const items = {
            [form.price + form.recipient_name]: form
        };
        StorageService.set("eyJpdiI6InlrRnE4anBOQn", items);
    }

    recountCart(dispatch);
    showMiniCart(dispatch);
}

const sendCart = ({data, dispatch}) => {
	const token = Cookies.get('token');
	data.append('goods', JSON.stringify(StorageService.get('eyJpdiI6IkFwbUtBWmpDbm')));
	data.append('certificates', JSON.stringify(StorageService.get('eyJpdiI6InlrRnE4anBOQn')));
	data.append('token', token);
	const jsonObject = Object.fromEntries(data);

	return axios.get(constants.API_BASE_URL + '/sanctum/csrf-cookie', {withCredentials: true}).then(() => {
      axios.post(constants.API_URL + '/order', {data: encrypt(JSON.stringify(jsonObject))}).then(resultData => {
      	const response = decrypt(resultData.data);

		if (response.success) {
			if(typeof response.data.redirect !== 'undefined') {
				window.location.replace(response.data.redirect);
			} else {
              window.location = "/order/success/" + response.data.order;
			}
		}else{
			messages(dispatch, 'sendOrderForm', response.success, response.message, 'left');
		}
      });
    });
};

export {
    addToCart,
    productChangeQuantity,
    productRemove,
    addToCartCertificat,
    certificateRemove,
    recountCart,
    showMiniCart,
    hiddeMiniCart,
    setDelivery,
    sendCart
};