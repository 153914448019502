import constants from "../constants";

const pageState = {
    content: {},
    banners: {},
	looks: {},
    menu: {}
};

const PageReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case constants.GET_PAGE: {
            return {
                ...state,
                content: payload.data.page.content,
                banners: payload.data.page.banners,
				looks: payload.data.page.looks,
                menu: payload.data.page.menu
            };
        }
        default:
            return {...state};
    }
};

export {
    pageState,
    PageReducer
};