import React from "react";
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useDataState} from "../../../state/context";
import styles from './index.module.scss';

const Error = (props) => {
    const {t} = useTranslation();
	const {base} = useDataState();

    return (
        <div className={styles.container}>
            <div className={styles.container_header_error}>
              	<div className={styles.container_header_error_content}>
                  <h1>ошибка оплата не прошла</h1>
                  <Link className={styles.container_header_error_content_link} to={'/' + base.currentLang + '/cart'}>
                    <span className={styles.container_header_error_content_link_text}>{t("back_to_cart")}</span>
                  </Link>
              	</div>
            </div>
        </div>
    );
};

export default Error;