import React, {useEffect} from "react";
import {useDataDispatch} from "../../state/context";
import { setSSr } from "../../state/actions/BaseAction";
import styles from './index.module.scss';

const NoFound = () => {
    const dispatch = useDataDispatch();

    useEffect(() => {
        setSSr({dispatch, ssr:false});
    }, [dispatch]);

    return (
        <div className={styles.not_found}>
            <h1 className={styles.not_found_title}>404</h1>
            <p className={styles.not_found_text}>Запрашиваемая страница <br />не существует</p>
            <a className={styles.not_found_link} href="/">НА ГЛАВНУЮ</a>
        </div>
    )
}

export default NoFound