import React, {useEffect} from "react";
import {isMobile} from 'react-device-detect';
import TagManager from 'react-gtm-module';
import {Helmet} from "react-helmet";
import {useDataState, useDataDispatch} from "../../state/context";
import  useAsyncDataFetch  from "../Hooks/useAsyncDataFetch";
import {getShop} from "../../state/actions/ShopAction";
import queryString from 'query-string';
import StickyBox from "react-sticky-box";
import Grid from './grid/index';
import Sidebar from './sidebar/index';
import MobileGrid from './grid/mobile';
import NoFound from '../pages/NoFound'
import styles from './index.module.scss';

const Shop = (props) => {
    const {params} = props.match;
    const getParams = queryString.parse(props.location.search);
    const {shop, base} = useDataState();
    const {sort, color, discount, size, min_price, max_price, gender, search, attr, brand, page} = getParams;
    const dispatch = useDataDispatch();
    const {gen, cat, subcat} = params;
    const currPage = (typeof page === 'undefined' || isNaN(page) || page < 1) ? 1:page;

    const {isLoading} = useAsyncDataFetch({promiseFn: getShop, dispatch}, base.ssr, {
        gen,
        cat,
        subcat,
        sort,
		brand,
        color,
        discount,
        size,
        min_price,
        max_price,
        gender,
        search,
        attr,
        page: currPage
    });

	useEffect(() => {
      if (typeof window !== 'undefined' && shop.products !== null ) {

        const productArray = shop.products.map((product) => {
          return {
            item_id: product.article + product.color_num,
            item_name: product['title_' + base.currentLang],
            coupon: '',
            discount: 0,
            affiliation: 'SportSpirit',
            item_brand: product.brand,
            item_category: (typeof product['gen_title_' + base.currentLang] !== 'undefined') ? product['gen_title_' + base.currentLang]:'',
            item_category2: (typeof product.category[0] !== 'undefined') ? product.category[0]['title_' + base.currentLang]:'',
            item_category3: (typeof product.category[1] !== 'undefined') ? product.category[1]['title_' + base.currentLang]:'',
            item_variant: product.color,
			item_list_name: 'Product Category',
			item_list_id: 'category',
            price: product.price,
            currency: "MDL",
            quantity: 1
          };
        });

		if(productArray.length > 0) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'view_item_list',
              ecommerce: {
                items: productArray
              }
            }
           });
		}
      }
	}, [shop.products]);

    if (!base.success) {
        return <NoFound />;
    }

    return (
        <div className={styles.shop}>
          	{(typeof shop.seo.title !== 'undefined' && shop.seo.title[base.currentLang] !== null) ? (
              <Helmet>
                  <title>{shop.seo.title[base.currentLang]}</title>
                  <meta name="description" content={shop.seo.description[base.currentLang]} />
              </Helmet>
          	):null}
          
            {isMobile || base.mobile ? (
                <MobileGrid {...props} products={shop.products} 
                            mobile={base.mobile}
                            total={shop.total}
                            description={(shop.description !== 'undefined' && shop.description !== null) ? shop.description[gen+'_text_'+base.currentLang]:null} 
                            productSort={shop.productSort} i
                            isLoading={isLoading}
                            getParams={getParams}
                            pageCount={shop.pageCount}
                            page={currPage}
                />
            ) : (
                <>
                <Sidebar {...props} />
                <Grid {...props} products={shop.products}
                      total={shop.total}
                      description={(shop.description !== 'undefined' && shop.description !== null) ? shop.description[gen+'_text_'+base.currentLang]:null} 
                      productSort={shop.productSort} i
                      isLoading={isLoading}
                      getParams={getParams}
                      pageCount={shop.pageCount}
                      lang={base.currentLang}
                      page={currPage}
                />
                </>
            )}
        </div>
    );
};

export default Shop;
