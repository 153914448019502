import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const Prices = (props) => {
	const {t} = useTranslation();

    return (
        <div className={styles.items}>
            <div className={styles.items_base}>
                <span className={styles.items_base_value}>{props.price}</span>
                <span className={styles.items_base_currency}>{t("currency")}</span>
            </div>

            {props.old_price > 0 ? (
                <div className={styles.items_old}>
                    <span className={styles.items_old_value}>{props.old_price}</span>
                    <span className={styles.items_old_currency}>{t("currency")}</span>
                </div>
            ):null}
        </div>
    );
}

export default Prices