import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import Page from './page/index';
import styles from './index.module.scss';

const Sizes = (props) => {
    const {t} = useTranslation();
    const [page, setPage] = useState(false)

    const changeHandler = (value) => {
        props.data.setProductSize(value);
    }

    const getSizes = () => {
        setPage(true)
    }

    const closeSize = (e) => {
        setPage(e)
    }

	useEffect(() => {
        if (props.size.length === 1) {
			props.data.setProductSize({barcode: props.size[0].barcode, size: props.size[0].size_eu});
        }else{
			props.data.setProductSize(null);
		}
    }, []);

    return (
        <div className={styles.wrapper}>
            {typeof props.pageSize === 'undefined' ? (
          		<>
                <Page closeSize={closeSize} params={props.params} show={page} />
                <label className={styles.wrapper_label}>
                    {t('size')} ( <span className={styles.wrapper_label_link} onClick={() => getSizes()}>{t('get_size')}</span> )
                </label>
				</>
            ):null}
          	<div className={styles.wrapper_items}>
          	{props.size.map((size) => (
              <div className={`${styles.wrapper_items_item} ${props.data.productSize !== null && (size.barcode === props.data.productSize.barcode || props.size.length === 1) ? styles.wrapper_items_item_active:''}`} key={size.barcode} onClick={() => changeHandler({barcode: size.barcode, size: size.size_eu})}>{size.size_eu}</div>
             ))}
          </div>
        </div>
    );
}

export default Sizes