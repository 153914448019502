import React from 'react'
import {useDataState} from "../../../../state/context";
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss'

const Who = () => {
    const {t} = useTranslation();
    const {base} = useDataState();

    return (
        <div className={styles.menu}>
             <h3>{t("address")}</h3>
          <p>{t("who_part_1")}</p>
        </div>
    );
}

export default Who