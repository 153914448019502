import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import StickyBox from "react-sticky-box";
import {useDataState, useDataDispatch} from "../../../state/context";
import  useAsyncDataFetch  from "../../Hooks/useAsyncDataFetch";
import {addToCart} from "../../../state/actions/CartAction";
import {getPage} from "../../../state/actions/PageAction";
import {getProduct} from "../../../state/actions/ShopAction";
import Loader from '../../../ui/loader/index'
import Breadcrambs from './breadcrumbs/index'
import Images from './images/index'
import About from './about/index'
import Related from './related/index'
import NoFound from '../../pages/NoFound';
import ProductCard from './card';
import ProductDefault from './product';
import styles from './index.module.scss';

const ProductDetail = (props) => {
    const {t} = useTranslation();
    const {params} = props.match;
    const {shop, base, page} = useDataState();
    const [showPage, setShowPage] = useState(false)
    const [productSize, setProductSize] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const {slug} = params;
    const sizes = shop.product.data ? JSON.parse(shop.product.data) : {size: []}
    const dispatch = useDataDispatch();
    const [form, setForm] = useState({
        title: '',
        price: 0,
        select_price: "",
        custom_price: "",
        recipient_name: "",
        your_name: "",
        recipient_email: "",
        your_email: "",
        comment: "",
    });

    const closePage = (e) => {
        setShowPage(e)
    }

    const getPageData = (slug, dispatch) => {
        getPage({slug, dispatch});
        setShowPage(true);
    }

    const addToCartProduct = (slug) => {
        addToCart({slug, productSize, quantity, dispatch});
    }

    const changeHandler = (e) => {
        const name = e.target.name
        const value = e.target.value

        setForm({...form, [name]: value, title: shop.product['title_' + base.currentLang]})

        if (name === 'custom_price' && value !== "") {
            setProductSize(null)
            setForm({...form, [name]: value, select_price: "", price: value})
        }
    }

    const {isLoading} = useAsyncDataFetch({promiseFn: getProduct, dispatch}, base.ssr, {slug});
    if (isLoading || Object.keys(shop.product).length === 0) return <Loader />;

    if (!base.success) {
        return <NoFound />;
    }

    return (
        <>
        <div className={styles.wrapper}>
            <Breadcrambs lang={base.currentLang} data={shop.breadcrumbs}
                         title={shop.product['title_' + base.currentLang]}/>
            <div className={styles.row}>
                <div className={styles.row_col_left}>
                    <Images items={shop.product.image}/>
                </div>
                <div className={styles.row_col_right}>
                    <StickyBox offsetTop={100} offsetBottom={0}>
                        <div className={styles.content}>
                            <h1>{shop.product['title_' + base.currentLang]}</h1>
                            {shop.product.cert ? (
                                <ProductCard product={shop.product} formHandler={changeHandler} data={{form, setForm}}
                                             dataSize={{productSize, setProductSize}} Lang={base.currentLang}
                                             addProduct={addToCartProduct}/>
                            ) : (
                                <ProductDefault product={shop.product} params={params}
                                                data={{productSize, setProductSize}} qt={{quantity, setQuantity}}
                                                sizes={sizes} Lang={base.currentLang} addProduct={addToCartProduct}
                                                stores={base.stores}/>
                            )}
                          </div>
                    </StickyBox>
                </div>
            </div>
        </div>

        {shop.product['text_' + base.currentLang] ? (
            <About content={shop.product['text_' + base.currentLang]}/>
        ) : null}
        {shop.related.length > 0 ? (
            <Related related={shop.related}/>
        ) : null}
        </>
    );
}

export default ProductDetail