import React from 'react'
import {useTranslation} from 'react-i18next';
import {useDataDispatch} from "../../../../../state/context";
import {setDelivery} from "../../../../../state/actions/CartAction";
import Radio from '../../../../../ui/form/radio/index';
import styles from './index.module.scss';

const Step = (props) => {
    const {t} = useTranslation();
    const dispatch = useDataDispatch();
    const changeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setDelivery(dispatch, props.form.delivery, props.form.regionIdforApi, value);
        props.setForm({...props.form, [name]: value});
    }

    return (
        <div className={styles.content}>
            <Radio name="payment" changeVal={changeHandler} value="cash" label={t("payment_cash")} checked={props.form.payment} />
          	<Radio name="payment" changeVal={changeHandler} value="card" label={t("payment_card")} checked={props.form.payment} />
            <Radio name="payment" changeVal={changeHandler} value="fast_shift" label="FastShift" checked={props.form.payment} />
        </div>
    );
};

export default Step