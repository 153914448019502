import React, {useState} from 'react'
import {useTranslation} from 'react-i18next';
import NexArrowIcon from '../../../../ui/icons/nextArrow';
import './index.css';

const Subscribe = () => {
    const {t} = useTranslation();
    const [value, setValue] = useState('')

    const changeHandler = (e) => {
        setValue(e.target.value)
    }

    return (
        <div id="mlb2-6779866" className="newsletter ml-subscribe-form">
            <h4>{t("subscribe_title")}</h4>
            <div className="subscribe-form ml-block-success">
                <div className="form-section">
                    <p>{t("thk_for_subscription")}</p>
                </div>
            </div>
            <form className="newsletter__form ml-block-form" action="https://app.mailerlite.com/webforms/submit/u5d3c1" data-id="u5d3c1" method="POST" target="_blank">
                <input type="hidden" name="ml-submit" value="1" />
                <input type="email" name="fields[email]" className="newsletter__form-input" placeholder="Email*"
                       value={value} onChange={(e) => changeHandler(e)} autoComplete="email" spellCheck="false"
                       autoCapitalize="off" />

                <button type="submit" className="newsletter__form-btn">
                    <NexArrowIcon name="chevron-right" color="#fff" size={14}/>
                </button>
                <button disabled="disabled" type="button" className="loading">
                    <img loading="lazy" src="https://static.mailerlite.com/images/rolling@2x.gif" width="20" height="20" alt="" />
                </button>
            </form>
        </div>
    );
}

export default Subscribe