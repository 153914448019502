import React from "react";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom'
import styles from './index.module.scss';

const Sidebar = (props) => {
    const {t} = useTranslation();

    return (
        <aside className={styles.sidebar}>
            <h5>{t('suport')}</h5>
            <nav className={styles.sidebar__support__menu}>
                <ul className={styles.sidebar__menu_items}>
                    {typeof props.data !== 'undefined' ? props.data.top.map((item) => (
                        <li key={item.slug} className={styles.sidebar__menu_items_item}>
                            <Link to={`/${props.Lang}/page/${item.slug}`} className={`${styles.sidebar__menu_items_item_link} ${item.slug === props.slug ? styles.active : ''}`}>{item['title_' + props.Lang]}</Link>
                        </li>
                    )):null}
                </ul>
            </nav>

            <nav className={styles.sidebar__menu_small}>
                <ul className={styles.sidebar__menu_small_items}>
                    {typeof props.data !== 'undefined' ? props.data.bottom.map((item) => (
                        <li key={item.slug} className={styles.sidebar__menu_small_items_item}>
                            <Link to={`/${props.Lang}/page/${item.slug}`} className={`${styles.sidebar__menu_small_items_item_link} ${item.slug === props.slug ? styles.active : ''}`}>{item['title_' + props.Lang]}</Link>
                        </li>
                    )):null}
                </ul>
            </nav>
        </aside>
    )
}

export default Sidebar