import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Excerpt from "../../../HOC/excerpt";
import InfoIcon from '../../../ui/icons/info';
import Prices from './prices/index';
import Color from './color/index';
import Sizes from './sizes/index';
import Quantity from './quantity/index';
import styles from './index.module.scss';

const ProductDefault = (props) => {
	const params = props.params;
	const {t} = useTranslation();
	let quantityTotal = 1;

	const scrollHandler = (id) => {
		const element = document.getElementById(id);
        if(typeof element !== 'undefined' && element !== null) {
            const top = element.offsetTop - document.getElementById('header').offsetHeight;
            window.scrollTo({top: top, behavior: "smooth"});
        }
	}

    return (
        <>
        <Prices price={props.product.price} old_price={props.product.old_price}/>
        <p className={styles.content_article}>{t('nds')}
            <strong> {props.product.article}{props.product.color_num}</strong></p>
        <Color article={props.product.article} image={(typeof props.product.image[0] !== 'undefined') ? props.product.image[0]:''}
               colors={props.product.palette ? props.product.palette : {}} params={params}/>

        <Sizes data={{productSize: props.data.productSize, setProductSize: props.data.setProductSize}} params={params} size={props.sizes.size}/>
        {(props.sizes.size.length === 1 && props.product.cat !== 'gift' && props.product.cat !== 'accessories') ? (
            <div className={styles.content_last_size}>
                <InfoIcon name="info" color="red" size={13}/> {t('product_low_sizes', {sizes: props.sizes.size.length})}
            </div>
        ):null}

        <div className={styles.content_row}>
            <Quantity data={{quantity: props.qt.quantity, setQuantity: props.qt.setQuantity, quantityTotal: quantityTotal}}/>

            <button
                className={`${styles.add_to_cart} ${props.data.productSize == null ? styles.disabled : ''}`}
                disabled={props.data.productSize == null ? true : false}
                onClick={() => props.addProduct(props.product.slug)}>
                {props.data.productSize == null ? t('select_size') : t('add_to_cart')}
            </button>
        </div>
        </>
    )
}

export default ProductDefault