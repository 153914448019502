import React from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const News = (props) => {
    const settings =  {
        arrows: false,
        dots: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <div id="news" className={`${styles.news} ${props.scroll ? styles.news_hidden:''}`}>
            <div className={styles.news_item}>
                <Slider {...settings}>
                    {Object.entries(props.news).map(([key, val]) => (
                        <Link to="/" key={val.id} className={styles.news_item_link}>
                            <h3>{val['title_'+props.currentLang]}</h3>
                        </Link>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default News